import { Skeleton } from '@mui/material';
import { IndexTable } from '@shopify/polaris';

const rowMarkup = (length) =>
  [...Array(10)].map((_, i) => (
    <IndexTable.Row
      id={i}
      key={i}
      selected={false}
      position={i}
    >
      {[...Array(length)].map((a, idx) => (
        <IndexTable.Cell key={idx}>
          <Skeleton variant="text" />
        </IndexTable.Cell>
      ))}
    </IndexTable.Row>
  ));

export default rowMarkup;
