import { Button, Page } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import LocationTitle from 'src/components/title/location_title';
import MappingTableView from './mapping_table_view';
import { userMapping } from './user_mapping_modal';
import { isEmpty } from 'src/utils/type_check';
import locationStore from 'src/stores/location_store';

const MappingView = () => {
  const { t } = useTranslation('settings');
  const selectedLocation = locationStore((state) => state.getLocationId('mapping'));
  const { setLocationId: setSelectedLocation } = locationStore();

  return (
    <Page fullWidth>
      <LocationTitle
        handleLocationChange={(value) => setSelectedLocation('mapping', value)}
        selectedLocation={selectedLocation}
        title={t('settings:mapping.mapping_of')}
        primaryButton={
          <Button
            variant="primary"
            onClick={async () => {
              await userMapping({ locationId: selectedLocation });
            }}
            disabled={isEmpty(selectedLocation)}
          >
            {t('settings:mapping:user_mapping')}
          </Button>
        }
      />
      <MappingTableView selectedLocation={selectedLocation} />
      <div className="bottom-padding"></div>
    </Page>
  );
};

export default MappingView;
