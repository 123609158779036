import { Navigation } from '@shopify/polaris';
import {
  LocationFilledIcon,
  PersonLockFilledIcon,
  PersonLockIcon,
  LocationIcon,
  AppsFilledIcon,
  AppsIcon,
  CodeIcon,
  CodeAddIcon,
} from '@shopify/polaris-icons';
import React, { useState, useMemo } from 'react';
import StackedModal from 'src/components/dialog/stacked_modal';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import ChannelView from 'src/sections/settings/channels/channel_view';
import LocationView from 'src/sections/settings/location/locations/location_view';
import UsersView from 'src/sections/settings/users/users_view';
import CustomerInfo from 'src/components/cards/customer_info';
import MappingCodeView from 'src/sections/settings/mapping_code/mapping_code_view';
import { getUserName } from 'src/utils/format_data';
import Loading from 'src/components/loading';
import NavSectionVertical from 'src/components/navigation/vertical/nav_vertical';
import LocManView from 'src/sections/settings/location/location_management_view';
import { modalTitleHeight, modalTopMargin, modalVerPadding } from 'src/theme/spacing';
import PackingMaterialsView from 'src/sections/settings/location/packing_materials_view';
import { useGetRoleMenu } from 'src/api/menu_api';
import { chooseLan } from 'src/utils/language_code';
import { useGetMe } from 'src/api/user_api';
import SorterManagementView from './location/sorter_management_view';
import MachineView from './location/machine_view';
import MappingView from './mapping/mapping_view';

const SettingsModal = (props) => {
  const { t, i18n } = useTranslation();
  const [selectedNavItem, setSelectedNavItem] = useState('users');
  const { searchResults, searchLoading } = useGetRoleMenu();
  const { user: userData, searchLoading: userLoading } = useGetMe();

  const handleNavigationClick = (navItem) => {
    setSelectedNavItem(navItem);
  };

  const menuHelper = (menuName) => {
    switch (menuName) {
      case 'users':
        return {
          icon: PersonLockFilledIcon,
          selectedIcon: PersonLockIcon,
          component: <UsersView />,
        };
      case 'locations':
        return {
          icon: LocationFilledIcon,
          selectedIcon: LocationIcon,
          component: <LocationView setContent={handleNavigationClick} />,
        };
      case 'location management':
        return { component: <LocManView /> };
      case 'packing materials':
        return { component: <PackingMaterialsView /> };
      case 'sorter management':
        return { component: <SorterManagementView /> };
      case 'machine':
        return { component: <MachineView /> };
      case 'channels':
        return { icon: AppsFilledIcon, selectedIcon: AppsIcon, component: <ChannelView /> };
      case 'mapping':
        return {
          icon: CodeAddIcon,
          selectedIcon: CodeAddIcon,
          component: <MappingView />,
        };
      case 'mapping code':
        return {
          icon: CodeIcon,
          selectedIcon: CodeIcon,
          component: <MappingCodeView />,
        };
      default:
        return { icon: null, component: null };
    }
  };

  const findMenuItem = (menu, key) => {
    for (let item of menu) {
      const itemKey = item.menuNameEnglish.toLowerCase();
      if (itemKey === key) {
        return item;
      }
      if (item.menuChildren) {
        const foundItem = findMenuItem(item.menuChildren, key);
        if (foundItem) {
          return foundItem;
        }
      }
    }
    return null;
  };

  const renderContent = () => {
    if (searchLoading) {
      return;
    }
    const settings_menu = searchResults.find((x) => x.menuNameEnglish.toLowerCase() === 'setting');
    const selectedItem = findMenuItem(settings_menu.menuChildren, selectedNavItem);
    if (selectedItem) {
      const { component } = menuHelper(selectedItem.menuNameEnglish.toLowerCase());
      return <Box style={{ overflowY: 'auto', flexGrow: 1 }}>{component}</Box>;
    }
    return null;
  };

  const navItems = useMemo(() => {
    const generateNavItems = (menu) => {
      return menu.map((item) => {
        const itemKey = item.menuNameEnglish.toLowerCase();
        const subNavigationItems = item.menuChildren ? generateNavItems(item.menuChildren) : null;
        const { icon, selectedIcon } = menuHelper(itemKey);
        return {
          key: itemKey,
          onClick: () => handleNavigationClick(itemKey),
          label: chooseLan(item.menuNameLocal, item.menuNameEnglish),
          icon: icon,
          selectedIcon: selectedIcon,
          url: `#${itemKey}`,
          children: subNavigationItems
            ? subNavigationItems.map((subItem) => {
                return {
                  key: subItem.key,
                  onClick: () => handleNavigationClick(subItem.key),
                  label: subItem.label,
                };
              })
            : undefined,
        };
      });
    };
    if (searchLoading) {
      return;
    }

    const settings_menu = searchResults.find((x) => x.menuNameEnglish.toLowerCase() === 'setting');
    return generateNavItems(settings_menu?.menuChildren);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNavItem, searchResults]);

  const renderModalContent = (
    <Grid
      container
      sx={{ padding: 'var(--p-space-200) var(--p-space-2000)' }}
    >
      <Grid
        item
        xs={2.5}
        sx={{
          height: `calc(100vh - ${modalTopMargin} - 3*${modalVerPadding} - ${modalTitleHeight} )`,
        }}
      >
        <Box className={'settings-nav'}>
          {userLoading ? (
            <Loading />
          ) : (
            <Box
              sx={{
                backgroundColor: 'var(--p-color-bg-surface-secondary)',
                borderRadius: 'var(--p-border-radius-400) var(--p-border-radius-400) 0 0',
                border: '1px solid rgba(0, 0, 0, 0.1)',
                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
                borderBottomWidth: 0,
              }}
            >
              <CustomerInfo
                side={true}
                name={getUserName(userData, i18n)}
                description={userData?.loginId ?? ''}
                avatarSize="sm"
                avatarPaddingRight="12px"
              />
            </Box>
          )}

          <Navigation
            location="#"
            fill={true}
          >
            <Box
              paddingBlockEnd="var(--p-space-300)"
              margin={
                '0 calc(var(--p-space-300) - var(--pc-app-provider-scrollbar-width)) 0 var(--p-space-300)'
              }
            >
              <NavSectionVertical
                data={navItems}
                currentNav={selectedNavItem}
              />
            </Box>
          </Navigation>
        </Box>
      </Grid>
      <Grid
        item
        xs={9.5}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
          }}
        >
          {renderContent()}
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <StackedModal
      isOpen={props.isOpen}
      title={t('common:settings')}
      content={renderModalContent}
      onClose={props.action}
      sx={{
        position: 'absolute',
        top: modalTopMargin,
        transform: 'translateX(-50%)',
        height: `calc(100vh - ${modalTopMargin})`,
        width: `calc(100% - 2*${modalVerPadding} + var(--p-space-200))`,
        margin: 'auto',
        borderRadius: 'var(--p-border-radius-400) var(--p-border-radius-400) 0 0',
        backgroundColor: 'var(--p-color-bg)',
      }}
    />
  );
};

export default SettingsModal;
