import React, { useState, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Alert, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { packingMaterialFormSchema } from 'src/utils/schema';
import FormProvider from 'src/components/hook_form/form_provider';
import RHFTextField from 'src/components/hook_form/rhf_text_field';
import { Box, Card, InlineGrid, Button, BlockStack } from '@shopify/polaris';
import { MATERIAL_TYPE } from 'src/api/common_code/material_type';
import { chooseLan } from 'src/utils/language_code';
import { requiredField } from 'src/utils/format_data';
import { RHFAPISelect } from 'src/components/hook_form/rhf_select';
import { getCurrencyCode } from 'src/utils/language_code';
import { useGetLocations } from 'src/api/location_api';
import {
  useGetPackingMaterial,
  createPackingMaterial,
  editPackingMaterial,
} from 'src/api/packing_materials_api';
import { isEmpty } from 'src/utils/type_check';
import CommonSelect from 'src/components/hook_form/common_select';
import PackManHistoryTableView from 'src/sections/settings/location/table/pack_mat_history_table_view';
import { headers, EmptyStateMarkup } from './table/pack_mat_history_table_misc';
import RowMarkup from './table/pack_mat_history_table_row';
import useTable from 'src/hooks/use_table';

const PackingMaterialForm = ({ onSuccess, id }) => {
  const { t } = useTranslation(['settings']);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [params, setParams] = useState({
    pageSize: 20,
    pageNumber: 1,
    packingMaterialId: id,
  });
  const {
    searchResults: data = {},
    searchHistory: history = [],
    searchHistoryCount,
    searchLoading,
  } = useGetPackingMaterial(id, params);

  const table = useTable({
    data: history,
    totalDataCount: searchHistoryCount.allCount,
    headers: headers(),
    rowMarkup: (props) => <RowMarkup {...props} />,
    emptyStateMarkup: <EmptyStateMarkup />,
    showSearchFilter: false,
    selectable: false,
  });

  if (table.isRefetch.value) {
    setParams((prevParams) => ({
      ...prevParams,
      pageSize: table.rowsPerPage,
      pageNumber: table.page,
    }));
    table.onRefetched();
  }

  var isEdit = !isEmpty(data.packingMaterialId);

  const defaultValues = useMemo(
    () => ({
      locationId: data.locationId ?? '',
      packingMaterialNameLocal: data.packingMaterialNameLocal ?? '',
      packingMaterialType: data.packingMaterialType ?? '',
      weightUnit: data.weightUnit ?? '',
      dimensionUnit: data.dimensionUnit ?? '',
      weight: data.weight ?? 0,
      length: data.length ?? 0,
      width: data.width ?? 0,
      height: data.height ?? 0,
      currencyCode: data.currencyCode ?? getCurrencyCode(),
      purchaseCost: data.purchaseCost ?? 0,
      packingMaterialQuantity: data.packingMaterialQuantity ?? 0,
      packageId: data.packingMaterialId ?? 0,
      barcode: data.barcode ?? '',
    }),
    [data],
  );

  const methods = useForm({
    resolver: yupResolver(packingMaterialFormSchema(t)),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = methods;

  const locationId = watch('locationId');

  const { searchResults: locations } = useGetLocations({
    pageNumber: 1,
    pageSize: 1,
    isActive: true,
    locationId: locationId,
  });

  useEffect(() => {
    if (!isEmpty(data)) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (locationId) {
      const selectedLocation = locations[0];
      if (selectedLocation) {
        setValue('weightUnit', selectedLocation.weightUnit);
        setValue('dimensionUnit', selectedLocation.dimensionUnit);
      }
    }
  }, [locationId, locations, setValue]);

  const onSubmit = handleSubmit(async (form) => {
    setErrorMsg('');

    const apiFunction = isEdit
      ? editPackingMaterial(form, data.packingMaterialId)
      : createPackingMaterial(form);
    try {
      var res = await apiFunction;
      if (res.isSuccess) {
        if (isEdit) {
          setSuccessMsg(t('settings:packing_material.form.update_success'));
        } else {
          onSuccess();
        }
      } else {
        console.log('ERROR in handleSubmit', res);
        setErrorMsg(res.errorMessages[0] ?? '');
      }
    } catch (error) {
      reset();
      console.log(error);
      setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });

  const renderHistoryTable = isEdit && (
    <Box padding={0}>
      <Box paddingBlockEnd={200}>
        <Typography variant="body_md_semibold">{t('common:field.history')}</Typography>
      </Box>
      <PackManHistoryTableView
        table={table}
        loading={searchLoading}
      />
    </Box>
  );

  const renderForm = (
    <Stack spacing={1}>
      {!!errorMsg && (
        <Alert
          severity="error"
          onClose={() => setErrorMsg('')}
        >
          {errorMsg}
        </Alert>
      )}
      {!!successMsg && (
        <Alert
          severity="success"
          onClose={() => setSuccessMsg('')}
        >
          {successMsg}
        </Alert>
      )}
      <Card>
        <Box paddingBlockEnd={200}>
          <Typography variant="body_md_semibold">
            {t('settings:packing_material.form.package_information')}
          </Typography>
        </Box>
        <Stack spacing={2}>
          <InlineGrid
            columns={3}
            gap="200"
          >
            <RHFAPISelect
              name="locationId"
              displayName={requiredField(t('common:field.location'))}
              toOption={(r) => ({
                key: r.locationId,
                value: chooseLan(r.locationNameLocal, r.locationNameEnglish),
              })}
              useOptions={useGetLocations}
              params={{
                pageNumber: 1,
                pageSize: 100,
                isActive: true,
              }}
            />
            <RHFTextField
              name="packingMaterialNameLocal"
              displayName={requiredField(t('common:field.package_name'))}
            />
            <RHFTextField
              name="packageId"
              displayName={t('common:field.package_id')}
              disabled={true}
            />
          </InlineGrid>
          <InlineGrid
            columns={4}
            gap="200"
          >
            <RHFTextField
              name="barcode"
              displayName={t('common:field.barcode')}
            />
            <CommonSelect
              data={MATERIAL_TYPE}
              name="packingMaterialType"
              displayName={requiredField(t('common:field.package_type'))}
            />
            <RHFTextField
              type="number"
              name="purchaseCost"
              displayName={t('common:field.purchase_cost')}
            />
            <RHFTextField
              type="number"
              name="packingMaterialQuantity"
              displayName={t('common:field.quantity')}
            />
          </InlineGrid>
        </Stack>
      </Card>
      <Card>
        <InlineGrid
          columns={2}
          gap="200"
        >
          <Stack spacing={1}>
            <Typography variant="body_md_semibold">{t('common:dimensions.title')}</Typography>
            <InlineGrid
              columns={4}
              gap="200"
              alignItems="end"
            >
              <RHFTextField
                name="length"
                type="number"
                displayName={t('common:dimensions.length')}
              />
              <RHFTextField
                name="width"
                type="number"
                displayName={t('common:dimensions.width')}
              />
              <RHFTextField
                name="height"
                type="number"
                displayName={t('common:dimensions.height')}
              />
              <RHFTextField
                name="dimensionUnit"
                disabled
              />
            </InlineGrid>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="body_md_semibold">{t('common:dimensions.weight')}</Typography>
            <InlineGrid
              columns={2}
              gap="200"
              alignItems="end"
            >
              <RHFTextField
                name="weight"
                type="number"
                displayName={t('common:dimensions.weight')}
              />
              <RHFTextField
                name="weightUnit"
                disabled
              />
            </InlineGrid>
          </Stack>
        </InlineGrid>
      </Card>
      {renderHistoryTable}
    </Stack>
  );

  return (
    <FormProvider
      methods={methods}
      onSubmit={onSubmit}
    >
      {renderForm}
      <BlockStack inlineAlign="end">
        <Box paddingBlockStart={200}>
          <Button
            submit={true}
            loading={isSubmitting}
            size={'large'}
            variant="primary"
            disabled={!isDirty}
          >
            {data.locationId ? t('common:save') : t('common:submit')}
          </Button>
        </Box>
      </BlockStack>
    </FormProvider>
  );
};

export default PackingMaterialForm;
