import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InlineGrid, Card, Text, Box, BlockStack } from '@shopify/polaris';
import { isEmpty } from 'src/utils/type_check';

const ChannelDetails = ({ channelData }) => {
  const { t } = useTranslation();

  const renderForm = (
    <Stack spacing={1}>
      <Card>
        <Box paddingBlockEnd={200}>
          <Typography variant="body_md_semibold">{t('common:field.channel_name')}</Typography>
        </Box>
        <InlineGrid
          columns={2}
          gap="200"
        >
          <TextInputFilled
            name={channelData.clientNameLocal}
            displayName={t('common:field.nameLocal')}
          />
          <TextInputFilled
            name={channelData.clientNameEnglish}
            displayName={t('common:field.nameEnglish')}
          />
        </InlineGrid>
      </Card>

      <Card>
        <Box paddingBlockEnd={200}>
          <Typography variant="body_md_semibold">{t('common:field.section_address')}</Typography>
        </Box>
        <Stack spacing={2}>
          <InlineGrid
            columns={2}
            gap="200"
          >
            <TextInputFilled
              name={channelData.countryCode}
              displayName={t('common:field.country')}
            />
            <TextInputFilled
              name={channelData.postalCode}
              displayName={t('common:field.postal_code')}
            />
          </InlineGrid>
          <InlineGrid
            columns={2}
            gap="200"
          >
            <TextInputFilled
              name={channelData.state}
              displayName={t('common:field.province')}
            />
            <TextInputFilled
              name={channelData.city}
              displayName={t('common:field.city')}
            />
          </InlineGrid>
          <TextInputFilled
            name={channelData.address1Local}
            displayName={t('common:field.address1')}
          />
          <TextInputFilled
            name={channelData.address2Local}
            displayName={t('common:field.address2')}
          />
          <TextInputFilled
            name={channelData.description}
            displayName={t('common:field.description')}
          />
        </Stack>
      </Card>
      <Card>
        <Stack spacing={2}>
          <Box>
            <Typography variant="body_md_semibold">
              {t('common:field.owner_information')}
            </Typography>
          </Box>

          <InlineGrid
            columns={2}
            gap="200"
          >
            <TextInputFilled
              name={channelData.representativeName}
              displayName={t('common:field.name')}
            />
            <TextInputFilled
              name={channelData.businessRegistrationNumber}
              displayName={t('common:field.business_reg_number')}
            />
          </InlineGrid>
          <InlineGrid
            columns={2}
            gap="200"
          >
            <TextInputFilled
              name={channelData.representativeTelNumber}
              displayName={t('common:field.phone')}
            />
            <TextInputFilled
              name={channelData.representativeEmail}
              displayName={t('common:field.email')}
            />
          </InlineGrid>
          <Typography variant="body_md_semibold">{t('common:field.staff_information')}</Typography>
          <InlineGrid
            columns={2}
            gap="200"
          >
            <TextInputFilled
              name={channelData.managerTelNumber}
              displayName={t('common:field.phone')}
            />
            <TextInputFilled
              name={channelData.managerEmail}
              displayName={t('common:field.email')}
            />
          </InlineGrid>
        </Stack>
      </Card>
    </Stack>
  );
  return <>{renderForm}</>;
};

const TextInputFilled = ({ name, displayName }) => {
  return (
    <BlockStack gap={150}>
      <Text
        variant="bodyMd"
        tone="subdued"
      >
        {displayName}
      </Text>
      <Box
        background="bg-surface-secondary"
        borderRadius={200}
        paddingInline={200}
        paddingBlock={150}
      >
        <Text variant="bodySm"> {!isEmpty(name) ? name : '-'} </Text>
      </Box>
    </BlockStack>
  );
};

export default ChannelDetails;
