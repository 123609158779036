import { rangeObj } from 'src/components/date_picker/dates';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

const rangeObject = rangeObj();

const initialState = {
  order: rangeObject.thismonth,
  inbound: rangeObject.thismonth,
  outbound: rangeObject.today,
  picking: rangeObject.today,
  shippingContainer: rangeObject.thismonth,
  purchaseOrder: rangeObject.thismonth,
  dashboard: rangeObject.today,
};

const store = (set, get) => ({
  ...initialState,

  setDate: (page, date) => {
    set((prev) => ({ ...prev, [page]: date }));
  },
  getDate: (page) => {
    var temp = get()[page];
    temp.period.until = new Date(temp.period.until);
    temp.period.since = new Date(temp.period.since);
    return temp;
  },
  reset: () => {
    set(initialState);
  },
});

const dateStore = create(
  persist(store, {
    name: 'date',
    storage: createJSONStorage(() => localStorage),
  }),
);

export default dateStore;
