import { CalloutCard } from '@shopify/polaris';
import React from 'react';

const EmptyCard = ({ title, description, onClick, actionTitle }) => {
  return (
    <CalloutCard
      title={title}
      primaryAction={{
        content: actionTitle,
        onAction: onClick,
        variant: 'primary',
      }}
    >
      <p>{description}</p>
    </CalloutCard>
  );
};

export default EmptyCard;
