import { Avatar, Text, Box, InlineStack, Button, Popover, ActionList } from '@shopify/polaris';
import { MenuVerticalIcon } from '@shopify/polaris-icons';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const ChannelInfoList = ({ avatar, name, description, onDeleteClick, onViewClick }) => {
  const [popoverActive, setPopoverActive] = useState(false);
  const { t } = useTranslation(['common']);
  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );
  const handleViewDetails = () => {
    onViewClick();
    setPopoverActive(false);
  };

  const handleUninstallDetails = async () => {
    await onDeleteClick();
    setPopoverActive(false);
  };

  return (
    <Box
      paddingBlockEnd={'400'}
      background="bg-transparent"
    >
      <InlineStack
        blockAlign="center"
        align="space-between"
      >
        <InlineStack
          blockAlign="center"
          align="start"
        >
          <Avatar
            source={avatar}
            size="lg"
            customer
          />
          <Box paddingInlineStart="200">
            <Text
              variant="bodyMd"
              fontWeight="bold"
              as="h3"
            >
              {name}
            </Text>
            <Text variant="bodySm">{description}</Text>
          </Box>
        </InlineStack>
        <Popover
          active={popoverActive}
          activator={
            <Button
              icon={MenuVerticalIcon}
              accessibilityLabel="Add theme"
              variant="tertiary"
              onClick={togglePopoverActive}
            />
          }
          autofocusTarget="first-node"
          onClose={togglePopoverActive}
          zIndexOverride={1500}
        >
          <ActionList
            items={[
              {
                content: t('common:view_details'),
                onAction: handleViewDetails,
              },
              {
                destructive: true,
                content: t('common:uninstall'),
                onAction: handleUninstallDetails,
              },
            ]}
          />
        </Popover>
      </InlineStack>
    </Box>
  );
};

export default ChannelInfoList;
