import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Page,
  Button,
  Card,
  BlockStack,
  Text,
  InlineGrid,
  InlineStack,
  Banner,
  Box,
} from '@shopify/polaris';
import LocationForm from 'src/sections/settings/location/locations/location_form';
import { getUserName } from 'src/utils/format_data';
import { print } from 'src/utils/log';
import { chooseLan } from 'src/utils/language_code';
import MapLocationToUserModal from './connect_location_modal';
import { XIcon } from '@shopify/polaris-icons';
import { isEmpty, isEmptyObject } from 'src/utils/type_check';
import { useGetMe } from 'src/api/user_api';
import { Stack } from '@mui/material';
import LocationList from './location_list_customer';
import LocationListAdmin from './location_list_admin';
import { mapLocationToCourierModal } from './connect_location_to_courier_modal';
import { deleteUserLocation } from 'src/api/mapping_api';
import { disconnectConfirm } from 'src/components/dialog/confirmation';
import locationStore from 'src/stores/location_store';

const MODES = {
  VIEW: 'view',
  FORM: 'form',
};

export default function LocationView({ setContent }) {
  const { t } = useTranslation(['settings']);
  const [mode, setMode] = useState(MODES.VIEW);
  const [data, setData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalLocation, setModalLocation] = useState('');
  const { user, customerId } = useGetMe();
  const [selectedUser, setSelectedUser] = useState(customerId ? user : {});
  const { setLocationId } = locationStore();

  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const toggleCreateModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleMode = () => {
    setData({});
    setMode(mode === MODES.VIEW ? MODES.FORM : MODES.VIEW);
  };

  const handleLocationInfoClick = (location) => {
    setData(location);
    setMode(MODES.FORM);
  };

  const handleViewStock = (locationId) => {
    setContent('location management');
    setLocationId('locationManagement', locationId);
  };

  const handleDeleteUserLocation = async (userId, locationId) => {
    setSuccessMsg('');
    setErrorMsg('');
    try {
      const confirmation = await disconnectConfirm();
      if (confirmation) {
        var res = await deleteUserLocation(userId, locationId);
        if (res.isSuccess) {
          setSuccessMsg('Location was disconnected');
        } else {
          setErrorMsg('Location was not disconnected');
        }
      }
    } catch (error) {
      console.log(error);
      setErrorMsg(error.message);
    }
  };

  const renderLocationPage = () => {
    return mode === MODES.VIEW ? (
      <BlockStack gap={300}>
        {isEmptyObject(selectedUser) ? (
          <LocationListAdmin
            customerId={selectedUser.userId}
            handleLocationInfoClick={handleLocationInfoClick}
            handleMode={handleMode}
          />
        ) : (
          <Stack spacing={1}>
            <Card roundedAbove="sm">
              <InlineStack align="space-between">
                <InlineGrid>
                  <Text
                    as="h3"
                    variant="bodyMd"
                    fontWeight="semibold"
                  >
                    {getUserName(selectedUser)}
                  </Text>
                  <Text
                    as="p"
                    variant="bodyMd"
                  >
                    {selectedUser.loginId}
                  </Text>
                </InlineGrid>
                {!customerId && (
                  <Button
                    icon={XIcon}
                    variant="plain"
                    tone="critical"
                    onClick={() => setSelectedUser({})}
                    accessibilityLabel="Edit"
                  />
                )}
              </InlineStack>
            </Card>
            <LocationList
              customerId={selectedUser.userId}
              toggleCreateModal={toggleCreateModal}
              setModalLocation={setModalLocation}
              handleLocationInfoClick={handleLocationInfoClick}
              handleDeleteUserLocation={handleDeleteUserLocation}
            />
          </Stack>
        )}
      </BlockStack>
    ) : (
      <LocationForm
        onSuccess={() => setMode(MODES.VIEW)}
        locationId={data.locationId}
        onConnect={async (selectedLocation, courierId, onSuccess) => {
          await mapLocationToCourierModal({ selectedLocation, courierId, onSuccess });
        }}
      />
    );
  };

  print('location_view load');
  return (
    <>
      <Page
        fullWidth
        title={
          mode === MODES.VIEW
            ? t('common:locations')
            : Object.keys(data).length === 0
              ? t('settings:locations.create')
              : chooseLan(data.locationNameLocal, data.locationNameEnglish)
        }
        backAction={
          mode === MODES.FORM ? { content: t('common:back'), onAction: handleMode } : null
        }
        primaryAction={
          mode === MODES.VIEW ? (
            isEmpty(customerId) ? (
              <Button
                variant="primary"
                onClick={handleMode}
              >
                {t('settings:locations.add_location')}
              </Button>
            ) : null
          ) : Object.keys(data).length === 0 ? null : (
            data.isActive && (
              <Button
                variant="tertiary"
                onClick={() => handleViewStock(data.locationId)}
              >
                {t('settings:locations.view_stock')}
              </Button>
            )
          )
        }
      >
        <Box paddingBlockEnd={200}>
          {!!successMsg && (
            <Banner
              tone="success"
              onDismiss={() => setSuccessMsg('')}
            >
              {successMsg}
            </Banner>
          )}
          {!!errorMsg && (
            <Banner
              tone="critical"
              onDismiss={() => setErrorMsg('')}
            >
              {errorMsg}
            </Banner>
          )}
        </Box>
        {renderLocationPage()}
        <div className="bottom-padding"></div>
      </Page>
      <MapLocationToUserModal
        isOpen={isModalOpen}
        onClose={() => {
          setModalLocation('');
          toggleCreateModal();
        }}
        locationId={modalLocation}
        onSuccess={(user) => {
          setSuccessMsg(
            t('settings:locations.user_location_connect', {
              user: user,
            }),
          );
        }}
      />
    </>
  );
}
