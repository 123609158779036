import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Page,
  BlockStack,
  Box,
  Card,
  InlineStack,
  InlineGrid,
  Text,
  Button,
} from '@shopify/polaris';
import { XIcon } from '@shopify/polaris-icons';
import { Stack, Typography } from '@mui/material';
import { useGetMe } from 'src/api/user_api';
import { useGetMappings } from 'src/api/mapping_api';
import UserAutocomplete from 'src/components/user_autocomplete';
import LocationMappingTableView from './location_mapping_table_view';
import ChannelMappingTableView from './channel_mapping_table_view';
import SupplierMappingTableView from './supplier_mapping_table_view';
import EmptyStateCard from 'src/components/cards/empty_state_card';
import { ROLES } from 'src/layouts/guard/role_guard';

const MappingCodeView = () => {
  const { t } = useTranslation(['settings', 'validation']);
  const { isAdmin, user, role } = useGetMe();

  const [selectedUser, setSelectedUser] = useState(isAdmin ? {} : user);

  const { searchResults: data, searchLoading: loading } = useGetMappings(selectedUser.userId);

  const handleSelectUser = (user) => {
    setSelectedUser(user);
  };

  const renderSelectedUser = (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 'var(--p-space-200)' }}>
      <Card roundedAbove="sm">
        <InlineStack align="space-between">
          <InlineGrid>
            <Text
              as="h3"
              variant="bodyMd"
              fontWeight="semibold"
            >
              {selectedUser.name}
            </Text>
            <Text
              as="p"
              variant="bodyMd"
            >
              {selectedUser.loginId}
            </Text>
          </InlineGrid>
          {isAdmin && (
            <Button
              icon={XIcon}
              variant="plain"
              tone="critical"
              onClick={() => setSelectedUser({})}
              accessibilityLabel="Edit"
            />
          )}
        </InlineStack>
      </Card>
    </Box>
  );
  const renderSelectUser = (
    <Card>
      <Typography variant="heading_sm">{t('common:field.customer')}</Typography>
      <UserAutocomplete onSelect={handleSelectUser} />
    </Card>
  );

  const renderLocationMap =
    data?.locationMappingInfo?.length === 0 ? null : (
      <Card roundedAbove="sm">
        <BlockStack gap="200">
          <Typography variant="body_md_semibold">
            {t('settings:mapping.location_mapping_code')}
          </Typography>
          <LocationMappingTableView
            data={data.locationMappingInfo}
            searchLoading={loading}
          />
        </BlockStack>
      </Card>
    );
  const renderChannelMap =
    data?.channelMappingInfo?.length === 0 ? null : (
      <Card roundedAbove="sm">
        <BlockStack gap="200">
          <Typography variant="body_md_semibold">
            {t('settings:mapping.channel_mapping_code')}
          </Typography>
          <ChannelMappingTableView
            data={data.channelMappingInfo}
            searchLoading={loading}
          />
        </BlockStack>
      </Card>
    );
  const renderSupplierMap =
    data?.supplierMappingInfo?.length === 0 ? null : (
      <Card roundedAbove="sm">
        <BlockStack gap="200">
          <Typography variant="body_md_semibold">
            {t('settings:mapping.supplier_mapping_code')}
          </Typography>
          <SupplierMappingTableView
            data={data.supplierMappingInfo}
            searchLoading={loading}
          />
        </BlockStack>
      </Card>
    );

  return (
    <Page
      fullWidth
      title={t('settings:mapping.mapping_code')}
    >
      <Stack spacing={1}>
        {selectedUser.userId ? (
          <>
            {renderSelectedUser}

            {renderLocationMap}
            {role !== ROLES.LOCATION_MANAGER && renderChannelMap}
            {role !== ROLES.LOCATION_MANAGER && renderSupplierMap}
          </>
        ) : (
          <>
            {renderSelectUser}

            <EmptyStateCard
              heading={t('validation:choose_customer_first')}
              image="/images/alertIcon.png"
              body={t('settings:mapping.select_customer_description')}
            ></EmptyStateCard>
          </>
        )}
      </Stack>

      <div className="bottom-padding"></div>
    </Page>
  );
};

export default MappingCodeView;
