import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '@shopify/polaris';
import LocationTitle from 'src/components/title/location_title';
import { print } from 'src/utils/log';
import PackManTableView from './table/pack_mat_table_view';
import PackingMaterialForm from 'src/sections/settings/location/packing_material_form';
import { isEmpty } from 'src/utils/type_check';
import locationStore from 'src/stores/location_store';

const MODES = {
  VIEW: 'view',
  FORM: 'form',
};

const PackingMaterialsView = () => {
  const { t } = useTranslation(['settings']);
  const selectedLocation = locationStore((state) => state.getLocationId('packingMaterial'));
  const { setLocationId: setSelectedLocation } = locationStore();

  const [selectedItem, setSelectedItem] = useState({});
  const [mode, setMode] = useState(MODES.VIEW);

  const handleRowClick = (item) => {
    setSelectedItem(item);
    setMode(MODES.FORM);
  };

  const handleNewForm = useCallback(() => {
    setSelectedItem({});
    setMode(MODES.FORM);
  }, []);

  const handleView = useCallback(() => {
    setMode(MODES.VIEW);
  }, []);

  const renderTitle = (
    <LocationTitle
      handleLocationChange={(value) => setSelectedLocation('packingMaterial', value)}
      selectedLocation={selectedLocation}
      title={t('settings:packing_material.pack_mat_of')}
      hasBottomPadding={false}
    />
  );

  const renderPackMaterialPage = () => {
    return mode === MODES.VIEW ? (
      <PackManTableView
        selectedLocation={selectedLocation}
        handleRowClick={handleRowClick}
        handleNewForm={handleNewForm}
      />
    ) : (
      <PackingMaterialForm
        onSuccess={handleView}
        id={selectedItem.id}
      />
    );
  };

  print('packing_materials load');
  return (
    <Page
      fullWidth
      title={
        mode === MODES.FORM
          ? !isEmpty(selectedItem)
            ? selectedItem.name
            : t('settings:packing_material.create_material')
          : renderTitle
      }
      primaryAction={
        mode === MODES.VIEW && {
          content: t('settings:packing_material.create_material'),
          onAction: handleNewForm,
        }
      }
      backAction={mode === MODES.FORM && { content: t('common:back'), onAction: handleView }}
    >
      {renderPackMaterialPage()}
      <div className="bottom-padding"></div>
    </Page>
  );
};

export default PackingMaterialsView;
