import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, IndexTable } from '@shopify/polaris';
import EmptyCard from 'src/components/cards/empty_card';
import { print } from 'src/utils/log';
import { useGetLocations } from 'src/api/location_api';
import Table from 'src/components/table/table';
import { cleanParams } from 'src/utils/url';
import i18n from 'src/locales/i18n';
import { Box, Typography } from '@mui/material';
import useTable from 'src/hooks/use_table';

const headers = () => {
  var nameLocal = { title: i18n.t('common:field.nameLocal') };
  var nameEnglish = { title: i18n.t('common:field.nameEnglish') };
  var country = { title: i18n.t('common:field.country') };
  var location_type = { title: i18n.t('common:field.location_type') };
  var section_dimension = { title: i18n.t('common:field.section_dimension') };
  var status = { title: i18n.t('common:field.status') };
  return [nameLocal, nameEnglish, country, location_type, section_dimension, status];
};

export const tabs = (count) => {
  var location = {
    label: i18n.t('common:field.location'),
    value: '',
    onClick: () => {},
    badge: <Badge>{count.location}</Badge>,
  };
  return [location];
};

const rowMarkup = ({ data }, handleLocationInfoClick) => {
  return data.map((loc, index) => {
    var {
      locationId,
      locationNameLocal,
      locationNameEnglish,
      countryCode,
      locationType,
      dimensionUnit,
      weightUnit,
      isActive,
    } = loc;
    var id = locationId;
    var width = '16.66%';
    return (
      <IndexTable.Row
        id={id}
        key={id}
        position={index}
        onClick={() => handleLocationInfoClick(loc)}
      >
        <Box
          component={IndexTable.Cell}
          sx={{ width: width }}
        >
          <button className="text-button">
            <Typography variant="body_md">{locationNameLocal}</Typography>
          </button>
        </Box>
        <Box
          component={IndexTable.Cell}
          sx={{ width: width }}
        >
          {locationNameEnglish}
        </Box>
        <Box
          component={IndexTable.Cell}
          sx={{ width: width }}
        >
          {countryCode}
        </Box>
        <Box
          component={IndexTable.Cell}
          sx={{ width: width }}
        >
          {locationType}
        </Box>
        <Box
          component={IndexTable.Cell}
          sx={{ width: width }}
        >
          {`${dimensionUnit}, ${weightUnit}`}
        </Box>
        <Box
          component={IndexTable.Cell}
          sx={{ width: width }}
        >
          {isActive ? (
            <Badge tone="success">{i18n.t('common:active')}</Badge>
          ) : (
            <Badge>{i18n.t('common:deactivated')}</Badge>
          )}
        </Box>
      </IndexTable.Row>
    );
  });
};

export default function LocationListAdmin({ handleLocationInfoClick, handleMode }) {
  const { t } = useTranslation(['settings']);

  const [params, setParams] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const {
    searchResults: locations,
    searchCount,
    searchLoading,
  } = useGetLocations(cleanParams(params));

  const table = useTable({
    data: locations ?? [],
    totalDataCount: searchCount.location || 0,
    headers: headers(),
    rowsPerPageList: [10, 20, 30],
    tabItems: tabs(searchCount ?? {}),
    rowMarkup: (props) => rowMarkup(props, handleLocationInfoClick),
    selectable: false,
  });

  if (table.isRefetch.value) {
    setParams((prev) => ({
      ...prev,
      pageSize: table.rowsPerPage,
      pageNumber: table.page,
      locationName: table.debounceSearchQuery,
    }));
    table.onRefetched();
  }

  print('location_admin_list load');

  if (!searchLoading && locations.length === 0) {
    return (
      <Box>
        <EmptyCard
          title={t('settings:locations.no_locations')}
          description={t('settings:locations.create_description')}
          actionTitle={t('settings:locations.add_location')}
          onClick={handleMode}
        />
      </Box>
    );
  }

  return (
    <Table
      useTable={table}
      isLoading={searchLoading}
    />
  );
}
