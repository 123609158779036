const style = (sx) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 'var(--p-space-200)',
    backgroundColor: 'white',
    py: '0px !important',
    px: sx.px,
  },
  '& .MuiFilledInput-root': {
    borderRadius: 'var(--p-space-200)',
    py: '0px !important',
    px: sx.px,
  },
  '& .MuiInputBase-root': {
    mt: sx.mt,
    height: sx.height,
  },
  '& .MuiInputBase-input': {
    padding: 'var(--p-space-150) var(--p-space-300) !important',
  },
  '& .MuiInputBase-multiline': {
    padding: '0px !important',
  },
});
const buttonStyle = () => ({
  borderRadius: 'var(--p-space-200)',
  height: '40px',
});
export const tableInputStyles = style({ height: '28px' });
export const tableIconInputStyles = style({ height: '28px', px: '14px' });
export const inputStyles = style({ mt: 'var(--p-space-100)' });
export const customInputStyles = (sx) => style(sx);

export const largeButtonStyle = buttonStyle();
