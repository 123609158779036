import { IndexTable } from '@shopify/polaris';
import { Typography } from '@mui/material';
import { chooseLan } from 'src/utils/language_code';

const rowMarkup = ({ data, selectedItems }, handleRowClick) => {
  return data.map((machine, index) => {
    const endpointsLength = machine.endpoints.length || 0;
    const id = machine.machineId;
    return (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedItems.includes(id)}
        position={index}
        onClick={() => handleRowClick(machine)}
      >
        <IndexTable.Cell>
          {chooseLan(machine.locationNameLocal, machine.locationNameEnglish)}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <button className="text-button">
            <Typography
              color="link"
              variant="body_md_medium"
            >
              {chooseLan(machine.machineNameLocal, machine.machineNameEnglish)}
            </Typography>
          </button>
        </IndexTable.Cell>
        <IndexTable.Cell>{machine.machineType}</IndexTable.Cell>
        <IndexTable.Cell>{endpointsLength}</IndexTable.Cell>
      </IndexTable.Row>
    );
  });
};
export default rowMarkup;
