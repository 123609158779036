import React, { useMemo, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Alert, IconButton, InputAdornment, Typography, Box } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { userFormSchema } from 'src/utils/schema';
import FormProvider from 'src/components/hook_form/form_provider';
import RHFTextField from 'src/components/hook_form/rhf_text_field';
import { RHFSelect } from 'src/components/hook_form/rhf_select';
import { useGetRoles } from 'src/api/role_api';
import {
  createUser,
  defaultCustomerParam,
  deleteUser,
  editUser,
  useGetMe,
  useGetUsersWithParams,
} from 'src/api/user_api';
import { InlineGrid, Card, Icon, Button } from '@shopify/polaris';
import { useBoolean } from 'src/hooks/use_boolean';
import { ViewIcon } from '@shopify/polaris-icons';
import { chooseLan } from 'src/utils/language_code';
import { getUserName, requiredField } from 'src/utils/format_data';
import { getDirtyFields } from 'src/utils/format_data';
import authStore from 'src/stores/auth_store';
import { deleteConfirm, logoutConfirm } from 'src/components/dialog/confirmation';
import { isEmpty } from 'src/utils/type_check';
import { toast } from 'react-toastify';
import { RHFAPIAutocomplete } from 'src/components/hook_form/rhf_autocomplete';
import { ROLES } from 'src/layouts/guard/role_guard';

const UserForm = ({ onSuccess, userData, handleMode }) => {
  const { t } = useTranslation(['settings', 'validation']);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const { logout } = authStore();

  const [roleType, setRoleType] = useState('');
  const { isAdmin, userId, customerId, role } = useGetMe();
  const { roleResults: roles } = useGetRoles();

  const isEdit = userData.userId;

  const defaultValues = useMemo(
    () => ({
      userFirstNameLocal: userData.userFirstNameLocal ?? '',
      userFirstNameEnglish: userData.userFirstNameEnglish ?? '',
      userLastNameLocal: userData.userLastNameLocal ?? '',
      userLastNameEnglish: userData.userLastNameEnglish ?? '',
      loginId: userData.loginId ?? '',
      loginPassword: '',
      roleId: userData.roleId ?? '',
      contactNumber: userData.contactNumber ?? '',
      upperUserId: customerId,
    }),
    [userData, customerId],
  );

  const methods = useForm({
    resolver: yupResolver(userFormSchema(t, !!isEdit)),
    defaultValues,
  });
  const password = useBoolean();

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty, dirtyFields },
    watch,
    setError,
    clearErrors,
  } = methods;

  useEffect(() => {
    methods.reset(defaultValues);
    // eslint-disable-next-line
  }, [userData]);

  useEffect(() => {
    const subscription = watch((value) => {
      const selectedRole = roles.find((role) => role.roleId === value.roleId);
      setRoleType(selectedRole ? selectedRole.roleType : '');
    });
    return () => subscription.unsubscribe();
  }, [watch, roles]);

  const handleDelete = async () => {
    try {
      const confirmation = await deleteConfirm({
        title: (
          <Trans
            i18nKey="settings:users.confirm_remove"
            components={{ name: getUserName(userData) }}
          />
        ),
        content: (
          <Trans
            i18nKey="settings:users.confirm_remove_body"
            components={{ name: getUserName(userData) }}
          />
        ),
      });
      if (confirmation) {
        var res = await deleteUser(userData.userId);
        if (res.isSuccess) {
          toast.success(t('settings:users.user_was_deleted'));
          onSuccess();
        } else {
          toast.error(t('settings:users.user_was_not_deleted'), '\n', res.errorMessages[0]);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(
        t('settings:users.user_was_not_deleted'),
        '\n',
        typeof error === 'string' ? error : error.message,
      );
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    var editingOwnRole = false;
    const apiFunction = isEdit ? editUser : createUser;
    data = getDirtyFields(dirtyFields, data);

    if (data.roleId && userId === userData.userId) editingOwnRole = true;
    if (roleType === ROLES.STAFF && !isEmpty(customerId)) data.upperUserId = customerId;
    if (roleType === ROLES.STAFF && isEmpty(data.upperUserId)) {
      setError('upperUserId', {
        type: 'manual',
        message: t('validation:upper_user_id_required'),
      });
      return;
    }
    if (roleType !== ROLES.STAFF && !isEmpty(data.upperUserId)) {
      data.upperUserId = '';
    }

    try {
      clearErrors('upperUserId');
      const res = await apiFunction(data, userData.userId);
      if (res.isSuccess) {
        if (isEdit) {
          if (editingOwnRole) {
            const confirmation = await logoutConfirm();
            if (confirmation) {
              await logout();
            }
          } else setSuccessMsg(t('settings:users.update_success'));
        } else onSuccess();
      } else {
        console.log(res);
        setErrorMsg(res.errorMessages[0] ?? '');
      }
    } catch (error) {
      reset();
      setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });

  const renderRoleOptions = useMemo(() => {
    const availableRoles =
      role === ROLES.CUSTOMER
        ? [ROLES.STAFF]
        : role === ROLES.LOCATION_MANAGER
          ? [ROLES.LOCATION_MANAGER, ROLES.WORKER]
          : [];
    const filteredRoles = isEmpty(availableRoles)
      ? roles
      : roles.filter((x) => availableRoles.includes(x.roleType));
    return filteredRoles.map((role) => (
      <option
        key={role.roleId}
        value={role.roleId}
      >
        {chooseLan(role.roleNameLocal, role.roleNameEnglish)}
      </option>
    ));
  }, [roles, role]);

  const renderForm = (
    <Stack spacing={1}>
      {!!errorMsg && (
        <Alert
          severity="error"
          onClose={() => setErrorMsg('')}
        >
          {errorMsg}
        </Alert>
      )}
      {!!successMsg && (
        <Alert
          severity="success"
          onClose={() => setSuccessMsg('')}
        >
          {successMsg}
        </Alert>
      )}
      <Card>
        <Stack spacing={2}>
          <InlineGrid
            columns={2}
            gap="200"
          >
            <RHFTextField
              name="userFirstNameLocal"
              displayName={requiredField(t('common:field.first_name'))}
            />
            <RHFTextField
              name="userLastNameLocal"
              displayName={t('common:field.last_name')}
            />
          </InlineGrid>
          <InlineGrid
            columns={2}
            gap="200"
          >
            <RHFTextField
              name="userFirstNameEnglish"
              displayName={t('common:field.first_name_english')}
            />
            <RHFTextField
              name="userLastNameEnglish"
              displayName={t('common:field.last_name_english')}
            />
          </InlineGrid>
          <InlineGrid
            columns={2}
            gap="200"
          >
            <RHFTextField
              name="contactNumber"
              displayName={t('common:field.phone')}
            />
            <RHFTextField
              name="loginId"
              displayName={requiredField(t('common:field.email'))}
              disabled={!!isEdit}
            />
          </InlineGrid>
          <RHFSelect
            name="roleId"
            native
            displayName={requiredField(t('common:field.role'))}
            disabled={isEdit && !isAdmin}
          >
            {renderRoleOptions}
          </RHFSelect>

          {!isEdit && (
            <RHFTextField
              name="loginPassword"
              displayName={requiredField(t('common:field.password'))}
              type={password.value ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={password.onToggle}
                      edge="end"
                    >
                      <Icon source={ViewIcon} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          {roleType === ROLES.STAFF && isEmpty(customerId) && (
            <RHFAPIAutocomplete
              displayName={requiredField(t('common:field.customer'))}
              name="upperUserId"
              useOptions={useGetUsersWithParams}
              params={{ ...defaultCustomerParam }}
              toOption={(r) => ({
                label: getUserName(r),
                value: r.userId,
              })}
              onChange={() => {}}
              searchParamName={'userName'}
            />
          )}
        </Stack>
      </Card>
    </Stack>
  );

  const renderDeleteCard = (
    <Card>
      <Stack
        display={'flex'}
        justifyContent={'flex-start'}
        spacing={1}
      >
        <Typography variant={'body_md_semibold'}>
          {t('settings:users.remove', { name: getUserName(userData) })}
        </Typography>
        <Typography
          variant={'body_md'}
          sx={{ pb: 1 }}
        >
          {t('settings:users.remove_cannot_reverse')}
        </Typography>
        <Box>
          <Button
            variant="primary"
            tone="critical"
            onClick={handleDelete}
          >
            {t('settings:users.remove', { name: getUserName(userData) })}
          </Button>
        </Box>
      </Stack>
    </Card>
  );

  return (
    <FormProvider
      methods={methods}
      onSubmit={onSubmit}
    >
      {renderForm}
      {isEdit && (
        <>
          <div style={{ height: '1rem' }} />
          {renderDeleteCard}
        </>
      )}

      <div style={{ height: '1rem' }} />
      <Stack
        direction={'row'}
        spacing={1}
        display={'flex'}
        sx={{ justifyContent: 'flex-end' }}
      >
        {isEdit && (
          <Button
            loading={isSubmitting}
            onClick={() => onSuccess()}
          >
            <Typography
              variant="body_md_medium"
              sx={{ color: 'var(--p-color-text-critical)' }}
            >
              {t('common:cancel')}
            </Typography>
          </Button>
        )}
        <Button
          submit={true}
          loading={isSubmitting}
          size={'large'}
          variant="primary"
          disabled={!isDirty}
        >
          {isEdit ? t('common:save') : t('common:submit')}
        </Button>
      </Stack>
    </FormProvider>
  );
};

export default UserForm;
