import { format, getTime, formatDistanceToNow } from 'date-fns';
import { isEmpty } from 'lodash';

// ----------------------------------------------------------------------

export function fDate(date, isUTC = false, newFormat) {
  const fm = newFormat || 'yyyy-MM-dd';
  var d = new Date(date);
  if (!isUTC) d = fUTCDate(date);
  return date ? format(d, fm) : null;
}

export function fAPIDate(date, isUTC = false) {
  return fDate(date, isUTC, 'yyyyMMdd');
}

export function fStringToDate(str) {
  const strDate = fStringToStringDate(str);
  if (strDate === null) return null;
  return fUTCDate(new Date(strDate));
}

export function fStringToStringDate(str) {
  if (isEmpty(str)) return null;

  const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;
  if (isoDatePattern.test(str)) {
    return str;
  }

  if (str.length === 8) {
    const year = str.substring(0, 4);
    const month = str.substring(4, 6);
    const date = str.substring(6, 8);
    return `${year}-${month}-${date}`;
  }

  return null;
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'yyyy-MM-dd p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function fUTCDate(date) {
  const dateObj = new Date(date);
  const ret = new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60000);
  return ret;
}
