import { IndexTable, Card, useBreakpoints } from '@shopify/polaris';
import TableFilter from './table_filter';
import { useTranslation } from 'react-i18next';
import rowMarkup from './skeleton/row_markup';
import TablePagination from './table_pagination';
import { Box, Typography } from '@mui/material';
import { isEmpty } from 'src/utils/type_check';

export default function Table({ useTable, isLoading = false }) {
  const { t } = useTranslation();

  return (
    <Card padding={0}>
      {!isEmpty(useTable.title) ? (
        <Box padding={'12px'}>
          <Typography variant={'heading_sm'}>{useTable.title}</Typography>
        </Box>
      ) : null}
      <Box>
        <TableFilter
          useTable={useTable}
          isLoading={isLoading}
        />
      </Box>
      <div
        id={'mint_table'}
        style={{
          overflowY: useTable.tableScroll ? 'auto' : null,
          maxHeight: useTable.tableScroll ? '650px' : null,
          paddingBottom: useTable.tableScroll ? '10px' : null,
        }}
      >
        <IndexTable
          condensed={useBreakpoints().smDown}
          itemCount={isLoading ? 1 : useTable.data.length}
          selectedItemsCount={
            useTable.allResourcesSelected ? t('common:all') : useTable.selectedResources.length
          }
          onSelectionChange={useTable.handleSelectionChange}
          headings={useTable.headers}
          promotedBulkActions={useTable.bulkAction}
          selectable={!isLoading && useTable.selectable}
          emptyState={
            useTable.emptyStateMarkup === undefined
              ? null
              : useTable.emptyStateMarkup || useTable.emptyStateMarkup()
          }
        >
          {isLoading ? rowMarkup(useTable.headers.length) : useTable.rowMarkup}
        </IndexTable>
      </div>
      {useTable.showPagination && (
        <TablePagination
          useTable={useTable}
          isLoading={isLoading}
        />
      )}
    </Card>
  );
}
