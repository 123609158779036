import { Icon, InlineStack } from '@shopify/polaris';
import { Box, Typography } from '@mui/material';

const BulkButton = ({ content, icon }) => {
  return (
    <Box paddingInline={'var(--p-space-100)'}>
      <InlineStack
        gap={100}
        blockAlign="center"
      >
        <Box
          width="var(--p-space-400)"
          height="var(--p-space-400)"
        >
          <Icon source={icon} />
        </Box>
        <Typography variant="body_sm">{content}</Typography>
      </InlineStack>
    </Box>
  );
};

export default BulkButton;
