import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Page, Button, Box, InlineStack } from '@shopify/polaris';
import LocationTitle from 'src/components/title/location_title';
import { print } from 'src/utils/log';

import MachineTableView from './table/machine_table_view';
import { Typography } from '@mui/material';
import { ArrowLeftIcon } from '@shopify/polaris-icons';
import MachineForm from './machine_form';
import { chooseLan } from 'src/utils/language_code';
import locationStore from 'src/stores/location_store';

const MODES = {
  VIEW: 'view',
  FORM: 'form',
};

const MachineView = () => {
  const { t } = useTranslation(['settings']);
  const [mode, setMode] = useState(MODES.VIEW);
  const selectedLocation = locationStore((state) => state.getLocationId('machine'));
  const { setLocationId: setSelectedLocation } = locationStore();

  const [data, setData] = useState({});

  const handleMode = () => {
    setData({});
    setMode(mode === MODES.VIEW ? MODES.FORM : MODES.VIEW);
  };

  const handleMachineClick = (machine) => {
    setData(machine);
    setMode(MODES.FORM);
  };

  print('machine load');
  return (
    <Page fullWidth>
      {mode === MODES.VIEW ? (
        <>
          <LocationTitle
            handleLocationChange={(value) => setSelectedLocation('machine', value)}
            selectedLocation={selectedLocation}
            title={t('settings:machine.machine_of')}
            includeAll={true}
            primaryButton={
              <Button
                variant="primary"
                onClick={handleMode}
              >
                {t('settings:machine.create_machine')}
              </Button>
            }
          />
          <MachineTableView
            selectedLocation={selectedLocation}
            handleRowClick={handleMachineClick}
          />
        </>
      ) : (
        <>
          <Box paddingBlockEnd={600}>
            <InlineStack blockAlign="center">
              <Button
                icon={ArrowLeftIcon}
                accessibilityLabel="Go back"
                onClick={handleMode}
                variant="tertiary"
              />

              <Box paddingInline={200}>
                <Typography variant="heading_lg">
                  {chooseLan(data.machineNameLocal, data.machineNameEnglish) ||
                    t('settings:machine.create_machine')}
                </Typography>
              </Box>
            </InlineStack>
          </Box>
          <MachineForm
            onSuccess={() => setMode(MODES.VIEW)}
            currentData={data}
          />
        </>
      )}
      <div className="bottom-padding"></div>
    </Page>
  );
};

export default MachineView;
