import i18n from 'src/locales/i18n';
import { isEmpty } from './type_check';

export const getLongLangCode = () => {
  const code = i18n.language;
  if (code === 'en') return 'en-US';
  if (code === 'en') return 'en-US';
  return 'en-US';
};

export const getShortLangCode = () => {
  return i18n.language;
};

export const chooseLan = (local, eng) => {
  const lan = getShortLangCode();
  var ret = lan === 'ko' ? local : eng;
  if (!isEmpty(ret)) return ret || '';
  if (isEmpty(local)) return eng || '';
  else return local || '';
};

export const getCurrencyCode = () => {
  const code = i18n.language;
  if (code === 'en') return 'USD';
  if (code === 'ko') return 'KRW';
  return '';
};
