import { Box } from '@mui/material';
import { Icon, IndexTable, TextField } from '@shopify/polaris';
import { useState } from 'react';
import { confirmable } from 'react-confirm';
import { useTranslation } from 'react-i18next';
import { connectUsersLocation } from 'src/api/mapping_api';
import { useGetUsersWithParams } from 'src/api/user_api';
import EmptyStateCard from 'src/components/cards/empty_state_card';
import { createConfirmation } from 'src/components/dialog/confirmation';
import StackedModal from 'src/components/dialog/stacked_modal';
import Table from 'src/components/table/table';
import useTable from 'src/hooks/use_table';
import { getUserName } from 'src/utils/format_data';
import { chooseLan } from 'src/utils/language_code';
import { isEmpty } from 'src/utils/type_check';

import { useCallback } from 'react';
import { SearchIcon } from '@shopify/polaris-icons';
export const userMapping = createConfirmation(confirmable(UserMappingModal));

function UserMappingModal({ show, proceed, locationId }) {
  const { t } = useTranslation(['settings']);
  const [params, setParams] = useState({ pageSize: 5, pageNumber: 1, isActive: true });
  const { searchResults, searchLoading } = useGetUsersWithParams(params);

  const headers = () => {
    var name = { title: t('common:field.name') };
    var role = { title: t('common:field.role') };
    var country = { title: t('common:field.country') };
    var email = { title: t('common:field.email') };
    return [name, role, country, email];
  };

  const rowMarkup = ({ data, selectedItems }) => {
    return data.map((user, index) => {
      var { userId, countryCode, loginId, roleNameLocal, roleNameEnglish } = user;
      var width = '25%';
      var id = userId;
      return (
        <IndexTable.Row
          id={id}
          key={id}
          selected={selectedItems.includes(id)}
          position={index}
          onClick={() => {}}
        >
          <Box
            component={IndexTable.Cell}
            sx={{ width: width }}
          >
            {getUserName(user)}
          </Box>
          <Box
            component={IndexTable.Cell}
            sx={{ width: width }}
          >
            {chooseLan(roleNameLocal, roleNameEnglish)}
          </Box>
          <Box
            component={IndexTable.Cell}
            sx={{ width: width }}
          >
            {countryCode}
          </Box>
          <Box
            component={IndexTable.Cell}
            sx={{ width: width }}
          >
            {loginId}
          </Box>
        </IndexTable.Row>
      );
    });
  };

  const table = useTable({
    data: searchResults ?? [],
    headers: headers(),
    rowMarkup: (props) => rowMarkup(props),
    showRowsPerPage: false,
    showPagination: false,
    resourceIdResolver: (d) => d.userId,
    emptyStateMarkup: (
      <EmptyStateCard
        heading={t('settings:users.no_user_data')}
        body={t('settings:users.search_another_user')}
        image="/images/user_empty_icon.png"
        card={false}
        width={60}
        height={60}
      />
    ),
  });

  if (table.isRefetch.value) {
    setParams((prev) => ({ ...prev, userName: table.debounceSearchQuery }));
    table.onRefetched();
  }

  const renderTable = (
    <Box>
      <SearchBar
        searchQuery={table.searchQuery}
        onChangeSearchQuery={table.onChangeSearchQuery}
        searchOnEnter={table.searchOnEnter}
      />
      <Table
        useTable={table}
        isLoading={searchLoading}
      />
    </Box>
  );

  return (
    <StackedModal
      isOpen={show}
      title={t('settings:mapping.user_mapping')}
      content={renderTable}
      primaryAction={{
        content: t('common:connect'),
        onAction: async () => {
          connectUsersLocation(table.selectedResources, locationId);
          proceed(true);
        },
        disabled: isEmpty(table.selectedResources),
      }}
      secondaryAction={{
        content: t('common:cancel'),
        onAction: () => {
          proceed(false);
        },
      }}
      onClose={() => {
        proceed(false);
      }}
    />
  );
}

function SearchBar({ searchQuery, onChangeSearchQuery, searchOnEnter }) {
  const handleChange = useCallback(
    (value) => {
      onChangeSearchQuery(value, searchOnEnter);
    },
    [onChangeSearchQuery, searchOnEnter],
  );

  return (
    <TextField
      value={searchQuery}
      onChange={handleChange}
      placeholder="Search user"
      prefix={<Icon source={SearchIcon} />}
    />
  );
}

export default SearchBar;
