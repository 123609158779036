import { Helmet } from 'react-helmet-async';
import { Page } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import ShippingContainersScanView from 'src/sections/outbound/shipping/view/shipping_containers_scan_view';
import { PATHS } from 'src/routes/paths';
import LocationTitle from 'src/components/title/location_title';
import locationStore from 'src/stores/location_store';

export default function ShippingContainerScanPage() {
  const { t } = useTranslation(['shipping']);
  const selectedLocation = locationStore((state) => state.getLocationId('shippingContainerScan'));
  const { setLocationId: setSelectedLocation } = locationStore();

  return (
    <>
      <Helmet>
        <title>{t('shipping:containers.scan_container')}</title>
      </Helmet>
      <Page fullWidth>
        <LocationTitle
          handleLocationChange={(value) => setSelectedLocation('shippingContainerScan', value)}
          selectedLocation={selectedLocation}
          title={t('shipping:containers.scan_container_of')}
          includeAll={false}
          backButtonUrl={PATHS.dashboard.outbound.shipping.containers.root}
        />
        <ShippingContainersScanView selectedLocation={selectedLocation} />
      </Page>
    </>
  );
}
