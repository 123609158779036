import numeral from 'numeral';
import { isEmpty } from './type_check';

// ----------------------------------------------------------------------

const engFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const korFormatter = new Intl.NumberFormat('ko-KR', {
  style: 'currency',
  currency: 'KRW',
});

export function fNumber(number) {
  return numeral(number).format();
}

export function fCurrency(number, code = 'USD') {
  if (isEmpty(number) || isEmpty(code)) return '';
  code = code.toUpperCase();
  var formatter;
  if (code === 'KRW') formatter = korFormatter;
  else if (code === 'USD') formatter = engFormatter;
  else formatter = korFormatter;
  return formatter.format(number);
}

export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function formatSlotNumber(number) {
  if (number >= 1 && number <= 200) {
    return number.toString().padStart(3, '0');
  } else {
    return '';
  }
}

export function getDimension(l, w, h, unit) {
  let dimensions = [];
  if (!isEmpty(l)) dimensions.push(l);
  if (!isEmpty(w)) dimensions.push(w);
  if (!isEmpty(h)) dimensions.push(h);
  if (dimensions.length === 0) {
    return '';
  }
  const dimensionValue = `${dimensions.join('×')} ${unit}`
  return dimensionValue;
}

export function getWeight(weight, unit) {
  if (isEmpty(weight)) return '';
  return  `${weight} ${unit}`;
}