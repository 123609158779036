import { confirmable } from 'react-confirm';
import StackedModal from 'src/components/dialog/stacked_modal';
import { useTranslation } from 'react-i18next';
import { BlockStack, InlineGrid } from '@shopify/polaris';
import { Stack, Typography, Alert } from '@mui/material';
import FormProvider from 'src/components/hook_form/form_provider';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { locationSchema, nullableInteger } from 'src/utils/schema';
import { createConfirmation } from 'src/components/dialog/confirmation';
import RHFTextField from 'src/components/hook_form/rhf_text_field';
import { requiredField } from 'src/utils/format_data';
import { useGetCouriers } from 'src/api/courier_api';
import { RHFAPISelect } from 'src/components/hook_form/rhf_select';
import { chooseLan } from 'src/utils/language_code';
import { useState, useMemo, useEffect } from 'react';
import CommonSelect from 'src/components/hook_form/common_select';
import { COUNTY_CODE_SHORT } from 'src/api/common_code/country_code';
import { ORDER_SHIPMENT_STATUS } from 'src/api/common_code/order_shipment_status';
import { useGetLocation, useGetLocations } from 'src/api/location_api';
import { postSorter, putSorter } from 'src/api/machine_api';
import { isEmpty } from 'src/utils/type_check';
import { useGetServiceCouriers } from 'src/api/courier_api';
import { getDirtyFields } from 'src/utils/format_data';
import { RHFAPIAutocomplete } from 'src/components/hook_form/rhf_autocomplete';

export const createSorterConfirmation = createConfirmation(confirmable(SorterModal));

function SorterModal({ show, proceed, currentData }) {
  const { t } = useTranslation(['validation', 'settings']);
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const { searchLocation: locationData } = useGetLocation(selectedLocation);
  const weightUnit = locationData?.weightUnit || '';
  const dimensionUnit = locationData?.dimensionUnit || '';

  const isEdit = !isEmpty(currentData?.doorId);

  const schema = Yup.object().shape({
    locationId: locationSchema(t),
    doorId: Yup.number()
      .typeError(t('validation:door_id_required'))
      .min(0, t('validation:non_negative'))
      .required(t('validation:door_id_required')),
    doorName: Yup.string(),
    weightMin: nullableInteger(t),
    weightMax: nullableInteger(t),
    lengthMax: nullableInteger(t),
    girthMax: nullableInteger(t),
    countryCode: Yup.string(),
    courierId: nullableInteger(t),
    courierServiceCode: Yup.string().required(t('validation:courier_service_code_required')),
    orderShipmentStatus: Yup.string(),
    sortingOrder: Yup.number()
      .typeError(t('validation:sorting_order_required'))
      .min(0, t('validation:non_negative'))
      .required(t('validation:sorting_order_required')),
  });

  const defaultValues = useMemo(
    () => ({
      locationId: currentData?.locationId || '',
      doorId: currentData?.doorId || '',
      doorName: currentData?.doorName || '',
      weightMin: currentData?.weightMin || '',
      weightMax: currentData?.weightMax || '',
      lengthMax: currentData?.lengthMax || '',
      girthMax: currentData?.girthMax || '',
      countryCode: currentData?.countryCode || '',
      courierId: currentData?.courierId || '',
      courierServiceCode: currentData?.courierServiceCode || '',
      orderShipmentStatus: currentData?.orderShipmentStatus || '',
      sortingOrder: currentData?.sortingOrder || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentData],
  );

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    watch,
    handleSubmit,
    setValue,
    formState: { isSubmitting, dirtyFields, isDirty },
  } = methods;

  const watchCourierId = watch('courierId');
  const watchLocation = watch('locationId');

  useEffect(() => setSelectedLocation(watchLocation), [watchLocation]);
  const onSubmit = handleSubmit(async (data) => {
    setErrorMsg('');
    const apiFunction = isEdit ? putSorter(data, watchLocation, data.doorId) : postSorter(data);
    data = getDirtyFields(dirtyFields, data);
    try {
      var res;
      res = await apiFunction;
      if (res.isSuccess) {
        proceed(true);
      } else {
        setErrorMsg(res.errorMessages[0]);
      }
    } catch (error) {
      setErrorMsg(typeof error === 'string' ? error : error.message);
      console.log(error);
    }
  });

  const renderUnits = (
    <>
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'flex-end'}
      >
        <RHFTextField
          name="weightMin"
          displayName={t('common:field.min_weight')}
          type="number"
        />
        <RHFTextField
          name="weightMax"
          displayName={t('common:field.max_weight')}
          type="number"
        />
        <div style={{ width: '10%' }}>
          <Typography variant="body_md">{weightUnit}</Typography>
        </div>
      </Stack>

      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'flex-end'}
      >
        <RHFTextField
          name="lengthMax"
          displayName={t('common:field.max_length')}
          type="number"
        />
        <RHFTextField
          name="girthMax"
          displayName={t('common:field.max_girth')}
          type="number"
        />
        <div style={{ width: '10%' }}>
          <Typography variant="body_md">{dimensionUnit}</Typography>
        </div>
      </Stack>
    </>
  );
  const content = (
    <FormProvider
      methods={methods}
      onSubmit={onSubmit}
    >
      <Stack spacing={1}>
        <InlineGrid
          columns={2}
          gap="200"
        >
          <BlockStack gap={100}>
            <Typography variant={'body_md'}>{requiredField(t('common:field.location'))}</Typography>
            <RHFAPIAutocomplete
              name="locationId"
              useOptions={useGetLocations}
              params={{
                pageSize: 5,
                pageNumber: 1,
                isActive: true,
              }}
              toOption={(r) => ({
                label: chooseLan(r.locationNameLocal, r.locationNameEnglish),
                value: r.locationId,
              })}
              searchParamName={'locationName'}
              onChange={() => {
                setValue('courierId', '');
                setValue('courierServiceCode', '');
              }}
              placeholder={''}
            />
          </BlockStack>
          <RHFTextField
            name="doorId"
            displayName={requiredField(t('common:field.door_id'))}
            type="number"
          />
        </InlineGrid>
        <InlineGrid
          columns={3}
          gap="200"
        >
          <RHFTextField
            name="doorName"
            displayName={t('common:field.door_name')}
          />
          <RHFTextField
            name="sortingOrder"
            displayName={requiredField(t('common:field.sorter_order'))}
            type="number"
          />
          <CommonSelect
            data={COUNTY_CODE_SHORT}
            name="countryCode"
            displayName={t('common:field.country')}
          />
        </InlineGrid>
        <InlineGrid
          columns={3}
          gap="200"
        >
          <RHFAPISelect
            name="courierId"
            displayName={t('common:field.courier')}
            toOption={(r) => ({
              key: r.courierId,
              value: chooseLan(r.courierNameLocal, r.courierNameEnglish),
            })}
            params={watchLocation}
            useOptions={useGetCouriers}
            disabled={!watchLocation}
            helperText={!watchLocation && t('validation:choose_location_first')}
          />
          <RHFAPISelect
            name="courierServiceCode"
            displayName={requiredField(t('common:field.courier_service_code'))}
            toOption={(r) => ({
              key: r.courierServiceCode,
              value: r.courierServiceCode,
            })}
            params={{
              locationId: watchLocation,
              courierId: watchCourierId,
            }}
            useOptions={useGetServiceCouriers}
            disabled={!watchCourierId}
            helperText={!watchCourierId && t('settings:sorter.select_courier')}
          />
          <CommonSelect
            data={ORDER_SHIPMENT_STATUS}
            name="orderShipmentStatus"
            displayName={t('common:field.status')}
          />
        </InlineGrid>
        {renderUnits}
      </Stack>
    </FormProvider>
  );
  return (
    <StackedModal
      isOpen={show}
      title={isEdit ? t('settings:sorter.edit_sorter') : t('settings:sorter.create_sorter')}
      content={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '70vh',
          }}
        >
          <div style={{ overflowY: 'auto', flexGrow: 1 }}>
            {!!errorMsg && (
              <Alert
                severity="error"
                onClose={() => setErrorMsg('')}
              >
                {errorMsg}
              </Alert>
            )}
            {content}
          </div>
        </div>
      }
      primaryAction={{
        content: isEdit ? t('common:save') : t('common:create'),
        onAction: onSubmit,
        loading: isSubmitting,
        isSubmit: true,
        disabled: !isDirty,
      }}
      secondaryAction={{
        content: t('common:cancel'),
        onAction: () => {
          proceed(null);
        },
      }}
      onClose={() => {
        proceed(null);
      }}
    />
  );
}

export default confirmable(SorterModal);
