import {
  Avatar,
  Text,
  Box,
  InlineStack,
  Button,
  Card,
  Popover,
  ActionList,
} from '@shopify/polaris';
import { ConnectIcon, MenuHorizontalIcon } from '@shopify/polaris-icons';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const ChannelInfoGrid = ({ avatar, name, isCustom, onButtonClick, buttonText, onViewClick }) => {
  const [popoverActive, setPopoverActive] = useState(false);
  const { t } = useTranslation(['common']);
  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );
  const handleViewDetails = () => {
    onViewClick();
    setPopoverActive(false);
  };
  return (
    <Box width="100%">
      <Card padding={300}>
        <InlineStack
          blockAlign="center"
          align="space-between"
          wrap={false}
        >
          <InlineStack
            blockAlign="center"
            align="start"
            wrap={false}
          >
            <Avatar
              source={avatar}
              size="xl"
              customer
            />
            <Box paddingInlineStart="200">
              <Text
                variant="bodyMd"
                fontWeight="bold"
                as="h3"
              >
                {name}
              </Text>
            </Box>
          </InlineStack>

          <InlineStack
            blockAlign="center"
            gap={200}
          >
            {isCustom && (
              <Popover
                active={popoverActive}
                activator={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      icon={MenuHorizontalIcon}
                      accessibilityLabel="Add theme"
                      onClick={togglePopoverActive}
                    />
                  </div>
                }
                autofocusTarget="first-node"
                onClose={togglePopoverActive}
                zIndexOverride={1500}
              >
                <ActionList
                  items={[
                    {
                      content: t('common:view_details'),
                      onAction: handleViewDetails,
                    },
                  ]}
                />
              </Popover>
            )}
            <Button
              onClick={() => {
                onButtonClick();
              }}
              icon={ConnectIcon}
            >
              {buttonText}
            </Button>
          </InlineStack>
        </InlineStack>
      </Card>
    </Box>
  );
};

export default ChannelInfoGrid;
