import { ENDPOINTS } from './endpoints';
import { fetcher, revalidateOnlyStaleOptions } from 'src/utils/axios';
import useSWR from 'swr';
import { useMemo } from 'react';
import { getURL } from 'src/utils/url';
import { isEmpty } from 'src/utils/type_check';

export function useGetCouriers(locationId) {
  const URL = ENDPOINTS.courier.get(locationId);
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );
  return memoizedValue;
}

export function useGetServiceCouriers(params) {
  var URL = null;
  if (!isEmpty(params?.locationId)) {
    URL = getURL(ENDPOINTS.courier.get_couriers(params.locationId), params);
  }
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );
  return memoizedValue;
}
