import React, { useState } from 'react';
import StackedModal from 'src/components/dialog/stacked_modal';
import { useTranslation } from 'react-i18next';
import { useGetClientChannels } from 'src/api/mapping_api';
import { Box, Stack, Typography } from '@mui/material';
import Loading from 'src/components/loading';
import { chooseLan } from 'src/utils/language_code';
import ChannelInfoGrid from 'src/components/cards/channel_info_grid';
import { Pagination, Button } from '@shopify/polaris';
import { postClientInstallChannel } from 'src/api/mapping_api';
import { toast } from 'react-toastify';
import { ENDPOINTS } from 'src/api/endpoints';
import { useEffect } from 'react';
import { mutate } from 'swr';
import { getURL } from 'src/utils/url';

const RegisterModal = ({ userId, isOpen, onClose, onCreate, onViewClick }) => {
  const { t } = useTranslation();
  const [userViewPage, setUserViewPage] = useState(1);
  const pageSize = 6;

  const params = {
    userId: userId,
    ClientType: 'channel',
    IsMapping: false,
    IsActive: true,
  };

  const { searchResults: channels = [], searchLoading: loading } = useGetClientChannels(params);

  useEffect(() => {
    if (isOpen) {
      mutate(getURL(ENDPOINTS.mapping.get_channels, params));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const startIndex = (userViewPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedChannels = channels.slice(startIndex, endIndex);

  const installChannel = async (userId, clientId) => {
    try {
      const data = {
        clientId: clientId,
        mappingCode: `${userId}-${clientId}`,
        userId: userId,
      };
      const response = await postClientInstallChannel(data);
      if (response.isSuccess) {
        onClose();
      } else {
        toast.error(t('common:error'), '\n', response.errorMessages[0]);
      }
    } catch (error) {
      toast.error(t('common:error'), '\n', error.message);
    }
  };

  const handleRegisterChannel = () => {
    onCreate();
    onClose();
  };

  const handleViewChannel = (channel) => {
    onViewClick(channel);
    onClose();
  };

  const renderChannels = () => {
    if (loading) {
      return <Loading />;
    }
    if (channels.length === 0) {
      return (
        <Box
          key="other-channel"
          sx={{ flex: '1 1 calc(50% - var(--p-space-200))', display: 'flex' }}
        >
          <ChannelInfoGrid
            name={t('settings:channels.other_channel')}
            onButtonClick={onCreate}
            buttonText={t('common:create')}
          />
        </Box>
      );
    }
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 'var(--p-space-300)' }}>
        {paginatedChannels.map((channel) => (
          <Box
            key={channel.clientId}
            sx={{ flex: '1 1 calc(50% - var(--p-space-200))', display: 'flex' }}
          >
            <ChannelInfoGrid
              avatar={channel.representativeImageUrl}
              name={chooseLan(channel.clientNameLocal, channel.clientNameEnglish)}
              onButtonClick={() => installChannel(userId, channel.clientId)}
              isCustom={channel.isCreatedByUser}
              buttonText={t('common:install')}
              onViewClick={() => handleViewChannel(channel)}
            />
          </Box>
        ))}
      </Box>
    );
  };

  const renderActivity = (
    <Stack>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        paddingBlockEnd={2}
      >
        <Typography variant={'body_md_semibold'}>{t('settings:channels.title')}</Typography>
        <Button onClick={handleRegisterChannel}>{t('settings:channels.create')}</Button>
      </Stack>
      {renderChannels()}
      <Box sx={{ display: 'flex', pt: 2, justifyContent: 'center' }}>
        <Pagination
          onPrevious={() => setUserViewPage((prev) => Math.max(prev - 1, 1))}
          onNext={() =>
            setUserViewPage((prev) => (prev * pageSize < channels.length ? prev + 1 : prev))
          }
          hasNext={userViewPage * pageSize < channels.length}
          hasPrevious={userViewPage > 1}
          label={
            <Typography variant="body_md">
              {`${startIndex + 1}-${Math.min(endIndex, channels.length)} of ${channels.length}`}
            </Typography>
          }
        />
      </Box>
    </Stack>
  );

  return (
    <StackedModal
      title={t('settings:channels.register_channels')}
      content={renderActivity}
      isOpen={isOpen}
      onClose={onClose}
      secondaryAction={{
        content: t('common:cancel'),
        onAction: onClose,
      }}
    />
  );
};
export default RegisterModal;
