import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '@shopify/polaris';
import LocManTableView from 'src/sections/settings/location/table/loc_man_table_view';
import CreateToteModal from 'src/sections/settings/location/create_tote_modal';
import LocationTitle from 'src/components/title/location_title';
import { print } from 'src/utils/log';
import RackForm from './rack_form';
import { isEmpty } from 'src/utils/type_check';
import locationStore from 'src/stores/location_store';
import { useGetLocationsManagement } from 'src/api/location_api';
import { cleanParams } from 'src/utils/url';

const MODES = {
  VIEW: 'view',
  FORM: 'form',
};

const LocManagerView = () => {
  const { t } = useTranslation(['settings']);
  const [type, setType] = useState('rack');
  const [mode, setMode] = useState(MODES.VIEW);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rack, setRack] = useState({});
  const [tote, setTote] = useState(null);
  const selectedLocation = locationStore((state) => state.getLocationId('locationManagement'));
  const { setLocationId: setSelectedLocation } = locationStore();
  const locationId = selectedLocation === 'all' ? '' : selectedLocation;

  const [params, setParams] = useState({
    pageSize: 20,
    pageNumber: 1,
    IsActive: true,
    locationId: locationId,
    locationType: '',
    temperatureType: '',
    q: '',
  });

  const { searchResults: data = {}, searchLoading } = useGetLocationsManagement(
    type,
    cleanParams(params),
  );

  const handleRowClick = (item) => {
    setRack(item);
    setMode(MODES.FORM);
  };

  const handleRowClickTote = (item) => {
    setIsModalOpen(true);
    setTote(item);
  };

  const toggleCreateModal = () => {
    setIsModalOpen(!isModalOpen);
    setTote(null);
  };

  const handleView = useCallback(() => {
    setRack({});
    setMode(MODES.VIEW);
  }, []);

  const handleNewForm = useCallback(() => {
    setRack({});
    setMode(MODES.FORM);
  }, []);

  const renderTitle = (
    <LocationTitle
      handleLocationChange={(value) => setSelectedLocation('locationManagement', value)}
      selectedLocation={selectedLocation}
      title={t('settings:locations_management.loc_man_of')}
      hasBottomPadding={false}
    />
  );

  const renderModals = (
    <CreateToteModal
      isOpen={isModalOpen}
      onClose={toggleCreateModal}
      isEdit={tote ? true : false}
      tote={tote}
    />
  );
  print('location_management load');

  const renderLocationManagementPage = () => {
    return mode === MODES.VIEW ? (
      <LocManTableView
        selectedLocation={selectedLocation}
        toggleToteModal={toggleCreateModal}
        toggleRackView={handleNewForm}
        type={type}
        setType={setType}
        handleRowClick={type === 'rack' ? handleRowClick : handleRowClickTote}
        data={data}
        setParams={setParams}
        searchLoading={searchLoading}
      />
    ) : (
      <RackForm
        onSuccess={() => setMode(MODES.VIEW)}
        rack={rack}
        onBack={handleView}
      />
    );
  };

  return (
    <>
      <Page
        fullWidth
        title={
          mode === MODES.FORM
            ? !isEmpty(rack)
              ? rack.rackNumber
              : t('settings:locations_management.create_rack')
            : renderTitle
        }
        primaryAction={
          mode === MODES.VIEW && data?.list?.length > 0
            ? type === 'rack'
              ? {
                  content: t('settings:locations_management.create_rack'),
                  onAction: handleNewForm,
                }
              : {
                  content: t('settings:locations_management.create_tote'),
                  onAction: toggleCreateModal,
                }
            : null
        }
        backAction={mode === MODES.FORM && { content: t('common:Back'), onAction: handleView }}
      >
        {renderLocationManagementPage()}
        <div className="bottom-padding"></div>
      </Page>
      {renderModals}
    </>
  );
};

export default LocManagerView;
