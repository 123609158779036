import { useEffect, useState } from 'react';
import { headers, tabs, bulkAction } from './machine_table_misc';
import rowMarkup from './machine_table_row';
import useTable from 'src/hooks/use_table';
import Table from 'src/components/table/table';
import { useGetMachines } from 'src/api/machine_api';

export default function MachineTableView({ selectedLocation, handleRowClick }) {
  selectedLocation = selectedLocation === 'all' ? '' : selectedLocation;
  const [params, setParams] = useState({
    pageSize: 20,
    pageNumber: 1,
    locationId: selectedLocation,
  });

  const { searchResults: data, searchCount: count, searchLoading } = useGetMachines(params);

  const table = useTable({
    tabItems: tabs(count),
    totalDataCount: count?.totalCount,
    data: data,
    headers: headers(),
    bulkAction: bulkAction,
    rowMarkup: (props) => rowMarkup(props, handleRowClick),
    resourceIdResolver: (d) => d.machineId,
    showSearchFilter: false,
  });

  if (table.isRefetch.value) {
    setParams((prevParams) => ({
      ...prevParams,
      pageSize: table.rowsPerPage,
      pageNumber: table.page,
      locationId: selectedLocation,
    }));
    table.onRefetched();
  }

  useEffect(() => {
    table.isRefetch.onTrue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  return (
    <Table
      useTable={table}
      isLoading={searchLoading}
    />
  );
}
