// .env > API_URL=http://34.64.121.236:8080/api/v1

const ROOTS = {
  auth: `/login`,
  user: '/user',
  location: '/location',
  packing_material: '/packing_material',
  common: '/common_code',
  role: '/role',
  mapping: '/mapping',
  purchase_order: '/purchase_order',
  client: '/client',
  courier: '/courier',
  product: '/product',
  stock: '/stock/management',
  inbound: '/inbound',
  order: '/order',
  outbound: '/outbound',
  menu: '/menu',
  machine: '/machine',
  excel: '/excel',
  dashboard: '/dashboard',
};

export const ENDPOINTS = {
  auth: {
    check_email: `${ROOTS.auth}/check/email`,
    login: `${ROOTS.auth}/login`,
    logout: `${ROOTS.auth}/logout`,
    password_otp: `${ROOTS.auth}/password/reset`,
    password_reset: `${ROOTS.auth}/password`,
    refresh: `${ROOTS.auth}/refresh`,
  },
  user: {
    get_me: `${ROOTS.user}/info`,
    get_main: `${ROOTS.user}/setting/main`,
    get_location: (locationId) => `${ROOTS.user}/setting/location/${locationId}`,
    get_customer: (customerId) => `${ROOTS.user}/setting/customer/${customerId}`,
    get_list: `${ROOTS.user}/list`,
    post: `${ROOTS.user}`,
    put: (id) => `${ROOTS.user}/${id}`,
  },
  dashboard: {
    get: `${ROOTS.dashboard}`,
  },
  common: {
    get: `${ROOTS.common}/filter`,
  },
  role: {
    get: `${ROOTS.role}`,
  },
  menu: {
    get_role_menu: `${ROOTS.menu}/role`,
  },
  mapping: {
    get_location_courier: (locationId) => `${ROOTS.mapping}/location_courier/${locationId}`,
    get_location_mapping: (locationId) => `${ROOTS.mapping}/location/mapping/${locationId}`,
    delete_location_courier: (locationId, courierId) =>
      `${ROOTS.mapping}/location_courier/${locationId}/${courierId}`,
    post_location_courier: `${ROOTS.mapping}/location_courier`,
    get_channels: `${ROOTS.mapping}/client`,
    get_channels_sort: `${ROOTS.mapping}/client/sort`,
    post_channel: `${ROOTS.mapping}/client`,
    delete_channel: (userId, clientId, mappingCode) =>
      `${ROOTS.mapping}/client/${userId}/${clientId}/${mappingCode}`,
    get_user_location: (userId) => `${ROOTS.mapping}/user_location/${userId}`,
    post_user_location: `${ROOTS.mapping}/user_location`,
    delete_user_location: (userId, locationId) =>
      `${ROOTS.mapping}/user_location/${userId}/${locationId}`,
    get_all: `${ROOTS.mapping}/code/all`,
  },
  location: {
    get: (id) => `${ROOTS.location}/${id}`,
    get_list: `${ROOTS.location}/list`,
    get_management_list: (type) => `${ROOTS.location}/management/list/${type}`,
    get_racks: (id) => `${ROOTS.location}/rack/${id}`,
    get_rack_info: (locationId, rackNumber) =>
      `${ROOTS.location}/rack/slot/${locationId}/${rackNumber}`,
    put_rack_info: (locationId, rackNumber) => `${ROOTS.location}/rack/${locationId}/${rackNumber}`,
    get_rack_barcode: (locationId, rackBarcode) =>
      `${ROOTS.location}/rack/slot/barcode/${locationId}/${rackBarcode}`,
    post: `${ROOTS.location}`,
    put: (id) => `${ROOTS.location}/${id}`,
    post_rack_or_tote: (type) => `${ROOTS.location}/${type}`,
    delete_rack_or_tote: (id, locationId, type) => `${ROOTS.location}/${type}/${locationId}/${id}`,
    update_tote: (id, locationId) => `${ROOTS.location}/tote/${locationId}/${id}`,
  },
  packing_material: {
    get_list: `${ROOTS.packing_material}/list`,
    get: (id) => `${ROOTS.packing_material}/${id}`,
    post: `${ROOTS.packing_material}`,
    put: (id) => `${ROOTS.packing_material}/${id}`,
    delete: (id) => `${ROOTS.packing_material}/${id}`,
  },
  order: {
    get_list: `${ROOTS.order}/list`,
    sort_list: `${ROOTS.order}/sort/list`,
    get: (id) => `${ROOTS.order}/${id}`,
    post: `${ROOTS.order}`,
    delete: (id) => `${ROOTS.order}/${id}`,
    mark_shipment: `${ROOTS.order}/shipment`,
    upsert: (id) => `${ROOTS.order}/upsert/${id}`,
    delete_item: (id, itemIndex) => `${ROOTS.order}/item/${id}/${itemIndex}`,
    post_import: `${ROOTS.order}/excel/import`,
  },
  purchase_order: {
    get_list: `${ROOTS.purchase_order}/list`,
    get: (id) => `${ROOTS.purchase_order}/${id}`,
    post: `${ROOTS.purchase_order}`,
    mark_ordered: (id) => `${ROOTS.purchase_order}/ordered/${id}`,
    mark_closed: (id) => `${ROOTS.purchase_order}/closed/${id}`,
    sort_list: `${ROOTS.purchase_order}/sort/list`,
    delete: (id) => `${ROOTS.purchase_order}/${id}`,
    delete_item: (purchaseOrderId, purchaseOrderLine) =>
      `${ROOTS.purchase_order}/item/${purchaseOrderId}/${purchaseOrderLine}`,
    upsert: (id) => `${ROOTS.purchase_order}/upsert/${id}`,
    shipment: (id) => `${ROOTS.purchase_order}/shipment/${id}`,
    post_import: `${ROOTS.purchase_order}/excel/import`,
  },
  product: {
    get_list: `${ROOTS.product}/list`,
    post_import: `${ROOTS.product}/excel/import`,
    post: `${ROOTS.product}/image_file`,
    get: (userId, countryCode, sku) => `${ROOTS.product}/${userId}/${countryCode}/${sku}`,
    put: (userId, countryCode, sku) => `${ROOTS.product}/${userId}/${countryCode}/${sku}`,
    put_image: (userId, countryCode, sku) =>
      `${ROOTS.product}/image/${userId}/${countryCode}/${sku}`,
    delete: (userId, countryCode, sku) => `${ROOTS.product}/${userId}/${countryCode}/${sku}`,
    print_barcode: `${ROOTS.product}/print/barcode`,
  },
  stock: {
    get_list: `${ROOTS.stock}/list`,
    sort_list: `${ROOTS.stock}/sort/list`,
    put: (id) => `${ROOTS.stock}/${id}`,
  },
  client: {
    get_channel: (clientId) => `${ROOTS.client}/${clientId}`,
    post_channel: `${ROOTS.client}`,
    get_list: `${ROOTS.client}/list`,
    sort_list: `${ROOTS.client}/sort/list`,
    delete: (id) => `${ROOTS.client}/${id}`,
    post: `${ROOTS.client}`,
    get: (id) => `${ROOTS.client}/${id}`,
    put: (id) => `${ROOTS.client}/${id}`,
  },
  courier: {
    get: (locationId) => `${ROOTS.courier}/${locationId}`,
    get_couriers: (locationId) => `${ROOTS.courier}/service/${locationId}`,
  },
  inbound: {
    get_list: `${ROOTS.inbound}/list`,
    get: (poId) => `${ROOTS.inbound}/${poId}`,
    get_item_history: (poId, purchaseOrderLine) => `${ROOTS.inbound}/${poId}/${purchaseOrderLine}`,
    sort_list: `${ROOTS.inbound}/sort/list`,
    post: `${ROOTS.inbound}`,
    delete_received: (receivingId, cancelReasonCode) =>
      `${ROOTS.inbound}/received/cancel/${receivingId}/${cancelReasonCode}`,
    putaway: {
      get: `${ROOTS.inbound}/put_away`,
      post: `${ROOTS.inbound}/put_away`,
    },
  },
  outbound: {
    get_list: `${ROOTS.outbound}/list`,
    sort_list: `${ROOTS.outbound}/sort/list`,
    get: (id) => `${ROOTS.outbound}/${id}`,
    picking: {
      get: (locationId) => `${ROOTS.outbound}/picking/${locationId}`,
      get_detail: (shipmentPickingId) => `${ROOTS.outbound}/picking/detail/${shipmentPickingId}`,
      get_sort: (shipmentPickingId) => `${ROOTS.outbound}/sorting/${shipmentPickingId}`,
      post: `${ROOTS.outbound}/picking`,
      post_complete: `${ROOTS.outbound}/picking/complete`,
      post_sort: `${ROOTS.outbound}/sorting/shipment/tote/mapping`,
      get_tote: (locationId, toteBarcode) =>
        `${ROOTS.outbound}/sorting/tote/${locationId}/${toteBarcode}`,
      print_label: `${ROOTS.outbound}/picking/tracking/print/label`,
      print_label_order: `${ROOTS.outbound}/picking/tracking/print/label/order`,
      post_order_complete: `${ROOTS.outbound}/picking/order/complete`,
    },
    packing: {
      get_single: (locationId, packingScanType, scanNumber) =>
        `${ROOTS.outbound}/packing/single/${locationId}/${packingScanType}/${scanNumber}`,
      get_multi: (locationId, packingScanType, scanNumber) =>
        `${ROOTS.outbound}/packing/multi/${locationId}/${packingScanType}/${scanNumber}`,
      post: `${ROOTS.outbound}/packing`,
    },
    issue: {
      put: (orderShipmentId) => `${ROOTS.outbound}/issue/${orderShipmentId}`,
    },
    shipping: {
      get_list: `${ROOTS.outbound}/shipping/containers/list`,
      sort_list: `${ROOTS.outbound}/shipping/containers/sort/list`,
      get: (locationId, courierTrackingNumber) =>
        `${ROOTS.outbound}/shipping/containers/scan/${locationId}/${courierTrackingNumber}`,
      get_container: (id) => `${ROOTS.outbound}/shipping/containers/detail/${id}`,
      put_container: (id) => `${ROOTS.outbound}/shipping/containers/${id}`,
      delete_container: (id) => `${ROOTS.outbound}/shipping/containers/${id}`,
      post_container: `${ROOTS.outbound}/shipping/containers`,
      get_container_by_scan: (locationId, courierTrackingNumber) =>
        `${ROOTS.outbound}/shipping/containers/scan/${locationId}/${courierTrackingNumber}`,
      post_scan_container: `${ROOTS.outbound}/shipping/containers/scan`,
      delete_package: (containerId, orderShipmentId) =>
        `${ROOTS.outbound}/shipping/containers/detail/${containerId}/${orderShipmentId}`,
      mark_shipped_container: `${ROOTS.outbound}/shipping/containers/shipped`,
      post: `${ROOTS.outbound}/shipped`,
      scan_container: `${ROOTS.outbound}/shipping`,
    },
  },
  machine: {
    get: (machineId) => `${ROOTS.machine}/${machineId}`,
    get_list: `${ROOTS.machine}/list`,
    post_machine: `${ROOTS.machine}`,
    put_machine: (machineId) => `${ROOTS.machine}/${machineId}`,
    delete_machine: (machineId) => `${ROOTS.machine}/${machineId}`,
    delete_machine_endpoint: (machineId, sequence) => `${ROOTS.machine}/${machineId}/${sequence}`,
    sorter: {
      get_list: `${ROOTS.machine}/sorter/list`,
      post: `${ROOTS.machine}/sorter`,
      put: (locationId, doorId) => `${ROOTS.machine}/sorter/${locationId}/${doorId}`,
      delete: (locationId, doorId) => `${ROOTS.machine}/sorter/${locationId}/${doorId}`,
    },
  },
  excel: {
    get_template: (type) => `${ROOTS.excel}/sample/${type}`,
  },
};
