import axiosInstance, {
  fetcher,
  noRevalidateOnlyStaleOptions,
  revalidateOnlyStaleOptions,
} from 'src/utils/axios';
import useSWR, { mutate } from 'swr';
import { ENDPOINTS } from './endpoints';
import { useMemo } from 'react';
import { getURL } from 'src/utils/url';
import { cloneAndCleanIsActive, isArray } from 'src/utils/type_check';

var currentURL = '';
var currentURLclient = '';

export const defaultSupplierParam = {
  pageSize: 5,
  pageNumber: 1,
  clientType: 'supplier',
  isActive: true,
};

export async function createChannel(data) {
  var ret = await axiosInstance.post(ENDPOINTS.client.post_channel, data);
  const params = {
    UserId: data.userId ?? '',
    ClientType: 'channel',
    IsMapping: true,
  };
  const URL = getURL(ENDPOINTS.mapping.get_channels, params);
  if (ret.data.isSuccess) mutate(URL, (d) => d, true);
  return ret.data;
}

export function useGetClients(params) {
  var clone = cloneAndCleanIsActive(params, params.clientId);
  const URL = getURL(ENDPOINTS.client.get_list, clone);
  currentURL = URL;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);

  const memoizedValue = useMemo(
    () => ({
      searchCount: data?.result?.count || {},
      searchResults: data?.result?.list || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );

  return memoizedValue;
}

export function useGetClient(id) {
  var URL = ENDPOINTS.client.get(id);
  if (!id) URL = null;
  currentURLclient = URL;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, revalidateOnlyStaleOptions);

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result[0] || {},
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );

  return memoizedValue;
}

export function useGetClientSortList() {
  const URL = getURL(ENDPOINTS.client.sort_list);

  const { data, isLoading, error, isValidating } = useSWR(
    URL,
    fetcher,
    noRevalidateOnlyStaleOptions,
  );

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.result || {},
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
    }),
    [data?.result, error, isLoading, isValidating],
  );

  return memoizedValue;
}

export async function deleteClient(ids) {
  if (!isArray(ids)) ids = [ids];
  var isSuccess = false;
  for (var i = 0; i < ids.length; i++) {
    var ret = await axiosInstance.delete(ENDPOINTS.client.delete(ids[i]));
    if (ret.data.isSuccess) {
      isSuccess = true;
    }
  }
  if (isSuccess) mutate(currentURL, (d) => d, true);
}

export async function postClient(data) {
  var ret = await axiosInstance.post(ENDPOINTS.client.post, data);
  return ret.data;
}

export async function putClient(id, data) {
  var ret = await axiosInstance.put(ENDPOINTS.client.put(id), data);
  mutate(currentURLclient, (d) => d, true);
  return ret.data;
}
