import { confirmable } from 'react-confirm';
import StackedModal from 'src/components/dialog/stacked_modal';
import { useTranslation } from 'react-i18next';
import { Stack, Alert } from '@mui/material';
import FormProvider from 'src/components/hook_form/form_provider';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { locationSchema, apiItemsSchema } from 'src/utils/schema';
import { createConfirmation } from 'src/components/dialog/confirmation';
import RHFTextField from 'src/components/hook_form/rhf_text_field';
import { chooseLan } from 'src/utils/language_code';
import { useState, useMemo, useEffect } from 'react';
import { createCourierFromLocation, useGetLocationCouriers } from 'src/api/mapping_api';
import Loading from 'src/components/loading';

export const mapLocationToCourierModal = createConfirmation(confirmable(MapLocationToCourierModal));

function MapLocationToCourierModal({ show, proceed, selectedLocation, courierId, onSuccess }) {
  const { t } = useTranslation(['settings']);
  const [errorMsg, setErrorMsg] = useState('');

  const { searchResults, searchLoading: loading } = useGetLocationCouriers({
    locationId: selectedLocation,
    courierId: courierId,
  });

  const data = searchResults[0];

  const name = chooseLan(data?.courierNameLocal, data?.courierNameEnglish);
  const schema = Yup.object().shape({
    locationId: locationSchema(t),
    courierId: Yup.string(),
    apiInfo: apiItemsSchema(),
    courierPriority: Yup.number().nullable(),
  });

  const currentApi = useMemo(() => {
    if (data?.apiInfo?.length === 0) return [];
    return data?.apiInfo?.map((i) => ({
      key: i.key,
      value: i.value || '',
    }));
  }, [data]);

  const defaultValues = useMemo(
    () => ({
      locationId: selectedLocation,
      courierId: courierId,
      apiInfo: currentApi || [],
      courierPriority: data?.courierPriority ?? 0,
    }),
    [data, currentApi, selectedLocation, courierId],
  );

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    reset,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const watchApi = watch('apiInfo');
  const onSubmit = handleSubmit(async (data) => {
    setErrorMsg('');
    try {
      var res;
      res = await createCourierFromLocation(selectedLocation, data);
      if (res.isSuccess) {
        proceed(true);
        onSuccess && onSuccess();
      } else {
        setErrorMsg(res.errorMessages[0]);
      }
    } catch (error) {
      setErrorMsg(typeof error === 'string' ? error : error.message);
      console.log(error);
    }
  });

  const content = (
    <FormProvider
      methods={methods}
      onSubmit={onSubmit}
    >
      <Stack spacing={1}>
        <RHFTextField
          name={`courierPriority`}
          displayName={t('settings:locations.courier_priority')}
          type="number"
        />
        {watchApi.map((obj, idx) => {
          return (
            <RHFTextField
              name={`apiInfo.${idx}.value`}
              displayName={`${name} ${obj.key}`}
            />
          );
        })}
      </Stack>
    </FormProvider>
  );

  if (loading) return <Loading />;

  return (
    <StackedModal
      isOpen={show}
      title={t('settings:channels.connect_title', { name })}
      content={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '70vh',
          }}
        >
          <div style={{ overflowY: 'auto', flexGrow: 1 }}>
            {!!errorMsg && (
              <Alert
                severity="error"
                onClose={() => setErrorMsg('')}
              >
                {errorMsg}
              </Alert>
            )}
            {content}
          </div>
        </div>
      }
      primaryAction={{
        content: t('common:apply'),
        onAction: onSubmit,
        loading: isSubmitting,
        isSubmit: true,
      }}
      secondaryAction={{
        content: t('common:cancel'),
        onAction: () => {
          proceed(null);
        },
      }}
      onClose={() => {
        proceed(null);
      }}
    />
  );
}

export default confirmable(MapLocationToCourierModal);
