import useTable from 'src/hooks/use_table';
import Table from 'src/components/table/table';
import { print } from 'src/utils/log';
import i18n from 'src/locales/i18n';
import { Badge, Icon, IndexTable, OptionList } from '@shopify/polaris';
import { useGetActiveUserForCustomer } from 'src/api/user_api';
import { Box, IconButton } from '@mui/material';
import { EditIcon } from '@shopify/polaris-icons';
import { getUserName } from 'src/utils/format_data';
import { useState } from 'react';

const headers = () => {
  var name = { title: i18n.t('common:field.name') };
  var email = { title: i18n.t('common:field.email') };
  return [name, email, { title: '' }];
};

const rowMarkup = ({ data }, handleClick) => {
  return data.map((user, index) => {
    var { userId, loginId } = user;
    var id = userId;
    var name = getUserName(user);
    var width = '50%';
    return (
      <IndexTable.Row
        id={id}
        key={id}
        position={index}
      >
        <Box
          component={IndexTable.Cell}
          sx={{ width: width }}
        >
          {name}
        </Box>
        <Box
          component={IndexTable.Cell}
          sx={{ width: width }}
        >
          {loginId}
        </Box>
        <Box
          component={IndexTable.Cell}
          sx={{ width: '20px' }}
        >
          <IconButton
            sx={{ padding: 0.1 }}
            onClick={() => handleClick(user)}
          >
            <Icon source={EditIcon} />
          </IconButton>
        </Box>
      </IndexTable.Row>
    );
  });
};

export const tabs = (count) => {
  var staff = {
    label: i18n.t('common:roles.staff'),
    value: '',
    onClick: () => {},
    badge: <Badge>{count.staff}</Badge>,
  };
  return [staff];
};

export const initialFilters = {
  isActive: {
    label: i18n.t('common:active'),
    value: true,
  },
};

const options = [
  {
    label: i18n.t('common:inactive'),
    value: false,
  },
];

export const filters = (state, setState) => {
  return [
    {
      key: 'isActive',
      label: i18n.t('common:active'),
      filter: (
        <OptionList
          onChange={(selected) => setState('isActive', selected[0])}
          options={options}
          selected={[state.isActive.value]}
        />
      ),
      pinned: true,
      shortcut: true,
    },
  ];
};

export function label(key, value) {
  switch (key) {
    case 'isActive':
      return value ? i18n.t('common:active') : i18n.t('common:inactive');
    default:
      return value;
  }
}

export default function StaffTable({ customerId, handleClick }) {
  const [getActive, setGetActive] = useState(true);
  const [q, setQ] = useState('');
  const { searchCount, staffList, searchLoading } = useGetActiveUserForCustomer(customerId, {
    q,
    isActive: getActive,
  });

  const table = useTable({
    data: staffList ?? [],
    headers: headers(),
    tabItems: tabs(searchCount ?? {}),
    rowMarkup: (props) => rowMarkup(props, handleClick),
    showRowsPerPage: false,
    showPagination: false,
    selectable: false,
    initialFilters: initialFilters,
    filters: (state, setState) => filters(state, setState),
    disambiguateLabel: label,
  });

  if (table.isRefetch.value) {
    setGetActive(table.filterValues.isActive);
    setQ(table.debounceSearchQuery);
    table.onRefetched();
  }

  print('staff table load');

  return (
    <Table
      useTable={table}
      isLoading={searchLoading}
    />
  );
}
